import { useState } from "react";
import { useTranslation } from "react-i18next";

interface FileUploderProps {
  onFilesChange: (files: File[]) => void;
  maxFiles?: number;
  allowedTypes?: string[];
}

const DEFAULT_ALLOWED_TYPES = ["image/jpeg", "image/png", "application/pdf"];

export function FileUploader({
  onFilesChange,
  maxFiles = 1,
  allowedTypes = DEFAULT_ALLOWED_TYPES,
}: FileUploderProps) {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const validateFiles = (
    selectedFiles: File[]
  ): { files: File[]; errors: string[] } => {
    const validFiles: File[] = [];
    const errorMessages: string[] = [];

    selectedFiles.forEach((file) => {
      if (!allowedTypes.includes(file.type)) {
        errorMessages.push(`${file.name}: ${t("file-uploader.errors.type")}`);
      } else {
        validFiles.push(file);
      }
    });

    if (validFiles.length + files.length > maxFiles) {
      errorMessages.push(
        `${t("file-uploader.errors.max-files")}: ${maxFiles.toString()}`
      );
    }

    return { files: validFiles, errors: errorMessages };
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    if (selectedFiles.length === 0) return;

    const { files: validFiles, errors } = validateFiles(selectedFiles);

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    setErrors([]);
    const updatedFiles = [...files, ...validFiles].slice(0, maxFiles);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
    e.target.value = "";
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = files.filter((_, idx) => idx !== index);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };

  return (
    <div className="">
      <input
        type="file"
        name="files"
        id="files"
        multiple={maxFiles > 1}
        accept={allowedTypes.join(",")}
        onChange={handleFileChange}
        className="hidden"
      />
      <label
        htmlFor="files"
        className="block text-sm font-medium mb-2 border bg-primary text-white rounded-full cursor-pointer"
      >
        {t("file-uploader.label")}
      </label>
      {files.length > 0 && (
        <ul className="text-sm mb-2">
          {files.map((file, index) => (
            <li
              className="flex text-xs justify-between px-4 items-center"
              key={index}
            >
              <span className="truncate">{file.name}</span>
              <button
                onClick={() => handleRemoveFile(index)}
                className="text-red-500 text-xs ml-2"
                type="button"
              >
                {t("file-uploader.remove")}
              </button>
            </li>
          ))}
        </ul>
      )}

      {errors.length > 0 && (
        <div className="text-red-500 text-xs mt-2">
          {errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
    </div>
  );
}
