import { Box, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TermsAndConditions } from "../TermsAndConditions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  outline: "none",
  boxShadow: 24,
  borderRadius: 4,
};

interface AcceptTermsModalProps {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onAccept: () => void;
}

export function AcceptTermsModal({
  isOpenModal,
  setIsOpenModal,
  onAccept,
}: AcceptTermsModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpenModal}
      onClose={() => setIsOpenModal(false)}
      aria-labelledby={t("login.legal")}
      aria-describedby="Términos y condiciones del usuario"
    >
      <Box
        sx={style}
        className="custom-scrollbar-light w-full px-2 lg:w-3/4  lg:px-32 py-8"
      >
        <TermsAndConditions />
        <div className="flex justify-center w-full mt-12">
          <button
            onClick={onAccept}
            className="bg-black transition-colors leading-5 hover:bg-opacity-90 text-white font-light rounded-3xl py-1 px-6"
          >
            {t("legal.accept")}
          </button>
        </div>
      </Box>
    </Modal>
  );
}
