import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useToast } from "../../context/toast-context";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services/user-service";

interface DeleteEmployeeProps {
  userId: number;
  updateEmployees: (updatedEmployees: any) => void;
  branchId?: number;
  size?: "small" | "default";
}

export function DeleteEmployee({
  userId,
  updateEmployees,
  size = "default",
  branchId,
}: DeleteEmployeeProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { showToast } = useToast();
  const userService = useMemo(() => new UserService(), []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setLoading(true);
    userService.deleteEmployees(
      userId,
      async (data) => {
        const getParams = branchId
          ? { limit: 100, offset: 0, branchid: branchId }
          : { limit: 100, offset: 0 };

        await userService.getEmployees(
          getParams,
          (data) => {
            updateEmployees(data.users);
            setLoading(false);
            showToast(t("employee.delete.success"), "success");
            setOpen(false);
          },
          (error) => {
            showToast(error, "error");
            setLoading(false);
            setOpen(false);
          }
        );
      },
      (error) => {
        showToast(error, "error");
        setLoading(false);
        setOpen(false);
      }
    );
  };

  return (
    <>
      <Button
        title={t("employee.delete.label")}
        onClick={handleClickOpen}
        sx={{
          margin: 0,
          minWidth: "fit-content",
          background: "transparent",
          border: "1px solid #EF0BB8",
          borderRadius: "50%",
          color: "#EF0BB8",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          width: size === "default" ? "2.5rem" : "1.7rem",
          height: size === "default" ? "2.5rem" : "1.7rem",
          padding: size === "small" ? "0.25rem" : "inherit",
        }}
      >
        <svg
          fill="#EF0BB8"
          width="16"
          height="16"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m19.587 16.001 6.096 6.096a1.015 1.015 0 0 1 0 1.435l-2.151 2.151a1.015 1.015 0 0 1-1.435 0L16 19.587l-6.097 6.096a1.014 1.014 0 0 1-1.434 0l-2.152-2.151a1.015 1.015 0 0 1 0-1.435l6.097-6.096-6.097-6.097a1.015 1.015 0 0 1 0-1.435L8.47 6.318a1.014 1.014 0 0 1 1.434 0L16 12.415l6.097-6.097a1.015 1.015 0 0 1 1.435 0l2.151 2.152a1.015 1.015 0 0 1 0 1.435l-6.096 6.096z" />
        </svg>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={t("employee.delete.label")}
        aria-describedby={t("employee.delete.label")}
      >
        <DialogTitle
          sx={{
            padding: "20px 24px",
            fontWeight: 400,
            fontSize: "16px",
            borderRadius: "30px",
            "&.MuiDialogTitle-root": {
              fontFamily: "Montserrat",
            },
          }}
          id="delete-employee"
        >
          {t("employee.delete.title")}
        </DialogTitle>
        <DialogActions>
          <Button
            sx={{
              fontFamily: "Montserrat",
              color: "#000",
              fontSize: "0.875rem",
              textTransform: "capitalize",
              fontWeight: 300,
            }}
            onClick={handleClose}
            color="inherit"
            disabled={loading}
          >
            {t("employee.delete.cancel")}
          </Button>
          <Button
            sx={{
              fontFamily: "Montserrat",
              backgroundColor: "#E60032",
              color: "#fff",
              fontSize: "0.875rem",
              textTransform: "capitalize",
              fontWeight: 300,
              width: "90px",
              "&.Mui-disabled": {
                opacity: "40%",
                color: "#fff",
              },
            }}
            onClick={handleDelete}
            disabled={loading}
          >
            {!loading ? (
              t("employee.delete.submit")
            ) : (
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1.5rem"
                className="animate-spin "
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
