import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Branch, EmployeeUser, Group, groupTypes } from "../../types";
import { BranchService } from "../../services/branch-service";
import { GroupService } from "../../services/group-service";
import { useToast } from "../../context/toast-context";
import {
  AddEmployeeFirstLoginReq,
  EmployeeReq,
} from "../../services/dto/request/user-request-dto";
import { UserService } from "../../services/user-service";
import { ButtonForm } from "./button-form";
import { Loading } from "../Loading";
import { Add } from "../icons/Add";
import { Delete } from "../icons/Delete";
import { AddGroupForm } from "./add-group-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

type AddEmployee = {
  name: string;
  email: string;
  hasQR: boolean;
  groupsNames: string[];
  groupTypes: string[];
};

interface AddEmployeeFormProps {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBranch?: Branch;
  updateBranch?: (branchId: number, amountAdded: number) => void;
  addEmployees?: (updatedEmployees: EmployeeUser[]) => void;
}

export function AddEmployeeForm({
  setIsModalOpen,
  selectedBranch,
  updateBranch,
  addEmployees,
}: AddEmployeeFormProps) {
  const { t } = useTranslation();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [branchesLoading, setBranchesLoading] = useState(
    selectedBranch ? false : true
  );
  const [equalSplitTip, setEqualSplitTip] = useState<boolean>(false);

  const [groups, setGroups] = useState<Group[]>([]);
  const [groupsLoading, setGroupsLoading] = useState(
    selectedBranch ? true : false
  );

  const [employees, setEmployees] = useState<AddEmployee[]>([
    {
      name: "",
      email: "",
      groupsNames: [],
      hasQR: false,
      groupTypes: [],
    },
  ]);
  const [branchId, setBranchId] = useState<number | null>(
    selectedBranch ? selectedBranch.branchId : null
  );

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const branchService = useMemo(() => new BranchService(), []);
  const groupService = useMemo(() => new GroupService(), []);
  const userService = useMemo(() => new UserService(), []);
  const { showToast } = useToast();

  const addEmployeeRow = () => {
    // Verificamos si el último row tiene todos los campos completados para evitar agregar innecesariamente
    const lastEmployee = employees[employees.length - 1];
    if (lastEmployee.name.trim() !== "" && lastEmployee.email.trim() !== "") {
      setEmployees([
        ...employees,
        {
          name: "",
          email: "",
          groupsNames: [],
          hasQR: false,
          groupTypes: [],
        },
      ]);
    } else {
      setError(t("branches.create.errors.complete-row"));
    }
  };

  const removeEmployeeRow = (index: number) => {
    setEmployees(employees.filter((_, i) => i !== index));
  };

  const handleGroupChange = (index: number, value: string[]) => {
    if (value.includes("add-new-group")) {
      setIsGroupModalOpen(true);
    } else {
      const updatedEmployees = [...employees];
      updatedEmployees[index].groupsNames = value;
      setEmployees(updatedEmployees);
    }
  };

  const handleGroupTypeChange = (index: number, value: string[]) => {
    const updatedEmployees = [...employees];
    updatedEmployees[index].groupTypes = value;
    updatedEmployees[index].groupsNames = value.includes("Grupo")
      ? updatedEmployees[index].groupsNames
      : [];

    updatedEmployees[index].hasQR = value.includes("Con QR");

    setEmployees(updatedEmployees);
    setError("");
  };

  const handleInputChange = (
    index: number,
    field: keyof EmployeeReq,
    value: string | null
  ) => {
    const updatedEmployees = [...employees];
    updatedEmployees[index] = {
      ...updatedEmployees[index],
      [field]: value === "-1" ? null : value,
    };
    setEmployees(updatedEmployees);
    setError("");
  };

  const onAddGroup = (newGroup: Group) => {
    setGroups((prev) => [...prev, newGroup]);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();

    if (!branchId) {
      setError(t("branches.create.errors.branch"));
      setLoading(false);
      return;
    }

    const formattedEmployees = employees.map((employee) => ({
      name: employee.name,
      email: employee.email,
      useQR: employee.hasQR,
      groupTypesQR: equalSplitTip
        ? false
        : employee.groupTypes.includes("Con QR"),
      groupsNames: employee.groupsNames,
    }));

    const params: AddEmployeeFirstLoginReq = {
      branchId: branchId,
      equalSplitTip: equalSplitTip,
      groups: equalSplitTip ? [] : groups,
      employees: formattedEmployees,
    };

    await userService.addEmployees(
      params,
      async (data) => {
        // Actualizar si se agrega desde branches
        updateBranch && updateBranch(branchId, data.length);
        await userService.getEmployees(
          {
            limit: 100,
            offset: 0,
          },
          (data) => {
            addEmployees && addEmployees(data.users);
          },
          (error) => showToast(error, "error")
        );

        showToast(t("employee.create.success"), "success");
      },
      (error) => showToast(error, "error")
    );

    setLoading(false);
    setIsModalOpen && setIsModalOpen(false);
  };

  // Si no viene una branch preseleccionada (Caso pantalla teams)
  // Debemos hacer su fetch para el select
  useEffect(() => {
    if (!selectedBranch) {
      const fetchBranches = async () => {
        setBranchesLoading(true);
        await branchService.getBranches(
          { limit: 100, offset: 0 },
          (data) => {
            setBranches(data.branches);
            setBranchesLoading(false);
          },
          (errorMessage) => {
            showToast(errorMessage, "error");
            setBranchesLoading(false);
          }
        );
      };

      fetchBranches();
    } else {
      setBranches([selectedBranch]);
      setEqualSplitTip(selectedBranch.equalSplitTip);
    }
  }, [selectedBranch, branchService, showToast]);

  // Cuando seleccionamos una sucursal cargamos su grupo
  useEffect(() => {
    if (branchId) {
      const selected = branches.find((branch) => branch.branchId === branchId);
      if (selected) {
        setEqualSplitTip(selected.equalSplitTip);
      }
      const fetchGroups = async () => {
        setGroupsLoading(true);
        await groupService.getGroups(
          parseInt(branchId.toString()),
          (data) => {
            setGroups(data);
            setGroupsLoading(false);
          },
          (errorMessage) => {
            showToast(errorMessage, "error");
            setGroupsLoading(false);
          }
        );
      };
      fetchGroups();
    }
  }, [groupService, showToast, branchId, branches]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-start w-full justify-between">
        {!selectedBranch && (
          <div className="flex flex-col mb-12 items-start px-6 gap-4 justify-start">
            <label htmlFor="branch">{t("employee.create.label-branch")}</label>
            {branchesLoading ? (
              <Loading sidebar={false} />
            ) : (
              <select
                className="px-2 py-1 h-full rounded-full border border-[#838383] outline-primary"
                value={branchId ?? ""}
                required
                name="branch"
                id="branch"
                onChange={(e) => setBranchId(Number(e.target.value))}
              >
                <option disabled value="">
                  {t("employee.create.select")}
                </option>
                {branches?.map((branch) => (
                  <option key={branch.branchId} value={branch.branchId}>
                    {branch.name}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}
      </div>
      {groupsLoading && <Loading sidebar={false} />}
      <div className="container mx-auto">
        {branchId &&
          employees.map((employee, index) => (
            <div
              className="flex justify-center items-center px-6 gap-4"
              key={index}
            >
              <div className="flex items-center w-full justify-between">
                <TextField
                  type="text"
                  fullWidth
                  label={t("employee.create.fullname")}
                  variant="standard"
                  required
                  value={employee.name}
                  onChange={(e) =>
                    handleInputChange(index, "name", e.target.value)
                  }
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#898989",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#7e7e7e",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#000",
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
              </div>

              <div className="flex items-center w-full justify-between">
                <TextField
                  fullWidth
                  type="email"
                  label={t("employee.create.email")}
                  required
                  variant="standard"
                  value={employee.email}
                  onChange={(e) =>
                    handleInputChange(index, "email", e.target.value)
                  }
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#898989",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#7e7e7e",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#000",
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
              </div>

              {!equalSplitTip && (
                <>
                  <div className="flex mt-6 items-center w-full max-w-[200px] justify-between">
                    <Select
                      sx={{
                        paddingX: 2,
                        paddingY: 1,
                        margin: 0,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        fontSize: "1rem",
                        height: "100%",
                        width: "200px",
                        minWidth: "100%",
                        maxWidth: "230px",
                        borderRadius: "9999px",
                        border: "1px solid",
                        borderColor: "#dfdfdf",
                        backgroundColor: "transparent",
                        outline: "none",
                        "&:focus": {
                          outlineColor: "primary.main",
                        },
                        "& .MuiSelect-select": {
                          padding: 0,
                          display: "block",
                          minWidth: 0,
                        },
                        "&.MuiInputBase-root.MuiOutlinedInput-root": {
                          padding: "4px 8px",
                        },
                        "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
                          {
                            WebkitTextFillColor: "#dfdfdf",
                            opacity: "70%",
                          },

                        fontWeight: "400",
                      }}
                      value={employee.groupTypes ?? ""}
                      required
                      multiple
                      input={<OutlinedInput />}
                      displayEmpty
                      onChange={(e) =>
                        handleGroupTypeChange(index, e.target.value as string[])
                      }
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <span className="block truncate ">
                              {t("select.default")}
                            </span>
                          );
                        }

                        return selected.join(", ");
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontFamily: "Montserrat",
                        }}
                        disabled
                        value=""
                      >
                        <span>{t("select.default")}</span>
                      </MenuItem>
                      {groupTypes.map((type) => (
                        <MenuItem
                          sx={{
                            fontFamily: "Montserrat",
                          }}
                          key={type.groupTypeId}
                          value={type.name}
                        >
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="flex mt-6 items-center w-full max-w-[200px] justify-between">
                    <Select
                      sx={{
                        paddingX: 2,
                        paddingY: 1,
                        margin: 0,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        fontSize: "1rem",
                        height: "100%",
                        width: "230px",
                        minWidth: "100%",
                        maxWidth: "230px",
                        borderRadius: "9999px",
                        border: "1px solid",
                        borderColor: "#838383",
                        backgroundColor: "transparent",
                        outline: "none",
                        "&:focus": {
                          outlineColor: "primary.main",
                        },
                        "& .MuiSelect-select": {
                          padding: 0,
                          display: "block",
                          minWidth: 0,
                        },
                        "&.MuiInputBase-root.MuiOutlinedInput-root": {
                          padding: "4px 8px",
                        },

                        fontWeight: "400",
                      }}
                      required
                      multiple
                      input={<OutlinedInput />}
                      disabled={!employee.groupTypes?.includes("Grupo")}
                      value={employee.groupsNames}
                      displayEmpty
                      onChange={(e) =>
                        handleGroupChange(index, e.target.value as string[])
                      }
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <span className="block truncate ">
                              {t("select.group")}
                            </span>
                          );
                        }

                        return selected.join(", ");
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontFamily: "Montserrat",
                        }}
                        disabled
                        value=""
                      >
                        <span>{t("employee.create.group")}</span>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontFamily: "Montserrat",
                        }}
                        value="add-new-group"
                      >
                        {t("groups.percentages.setup.group")}
                      </MenuItem>
                      {groups
                        .filter((group) => group.name !== "QR")
                        .map((group) => (
                          <MenuItem
                            sx={{
                              fontFamily: "Montserrat",
                            }}
                            key={group.name}
                            value={group.name}
                          >
                            {group.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </>
              )}
              <div className="flex mt-6 items-center">
                <FormControlLabel
                  sx={{
                    "& .MuiTypography-root": {
                      color: "#898989",
                      paddingLeft: "2px",
                      fontFamily: "Montserrat",
                    },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#898989",
                        padding: 0,
                        "&.Mui-checked": {
                          color: "#EF0BB8",
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 24,
                          margin: 0,
                          padding: 0,
                        },
                      }}
                      onChange={(e) => {
                        setError("");
                        if (!employee.groupTypes.includes("Con QR")) {
                          const updatedEmployees = [...employees];
                          updatedEmployees[index].hasQR = e.target.checked;
                          setEmployees(updatedEmployees);
                        }
                      }}
                      checked={
                        employee.hasQR ||
                        (!equalSplitTip &&
                          employee.groupTypes.includes("Con QR"))
                      }
                    />
                  }
                  label="QR"
                />
              </div>
              <div className="relative ml-4">
                {index !== 0 && (
                  <IconButton
                    aria-label="Eliminar empleado"
                    onClick={() => removeEmployeeRow(index)}
                    color="secondary"
                    sx={{
                      position: "absolute",
                      right: index === employees.length - 1 ? "-45px" : "-10px",
                      top: "50%",
                      transform: "translateY(-27%)",
                    }}
                  >
                    <Delete color="#A0ABBA" />
                  </IconButton>
                )}
                {index === employees.length - 1 && (
                  <IconButton
                    aria-label="Agregar empleado"
                    onClick={addEmployeeRow}
                    color="primary"
                    sx={{
                      position: "absolute",
                      right: "-10px",
                      top: "50%",
                      transform: "translateY(-27%)",
                    }}
                  >
                    <Add />
                  </IconButton>
                )}
              </div>
            </div>
          ))}
      </div>

      {error && <div className="mt-4 text-red text-center">{error}</div>}

      <div className="flex justify-center mt-12 mx-6">
        <div className="w-full max-w-sm">
          <ButtonForm loading={loading}>{t("add")}</ButtonForm>
        </div>
      </div>
      {isGroupModalOpen && branchId && (
        <Modal
          open={isGroupModalOpen}
          onClose={() => setIsGroupModalOpen(false)}
          aria-labelledby={t("teams.group.create.title")}
          aria-describedby={t("teams.group.create.title")}
        >
          <Box sx={style}>
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
              <h1>{t("teams.group.create.title")}</h1>
            </div>
            <div className="p-8">
              <AddGroupForm
                setIsModalOpen={setIsGroupModalOpen}
                addGroup={onAddGroup}
                branchNumberId={branchId}
              />
            </div>
          </Box>
        </Modal>
      )}
    </form>
  );
}
