import BaseService from "./base-service";
import { RequestDto } from "./dto/request/request-dto";

interface PdfBuffer {
  pdf: {
    type: "buffer";
    data: number[];
  };
}

export class PDFService extends BaseService {
  private resource = "/pdf";

  async getPDF(
    params: { userId: number },
    onSuccess: (data: PdfBuffer) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource, params);
    await this.doGet<PdfBuffer>(reqParams, onSuccess, onError);
  }
}
