import { useTranslation } from "react-i18next";
import Logo from "../assets/img/logos/X.svg";
import DivixLogo from "../assets/img/logos/divixNegro.png";

import { Avatar, Checkbox, CircularProgress, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { AcceptTermsModal } from "../components/modals/AcceptTermsModal";
import { ChangeAvatar } from "../components/teams/change-avatar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserService } from "../services/user-service";
import { useToast } from "../context/toast-context";
import { UnverifiedUser } from "../services/dto/response/user-response-dto";
import { DNIUploader } from "../components/DniUploader";
import { compressImage } from "../utils/compress-image";

interface FormDataValues {
  birthDate: string;
  dni: string;
  cuit: string;
  cbu: string;
  phone: string;
  frontDNI: File | null;
  backDNI: File | null;
}

export function VerifyEmployee() {
  const [employee, setEmployee] = useState<UnverifiedUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [isVerifying, setIsVerifying] = useState(false);
  const [frontDNI, setFrontDNI] = useState<File | null>(null);
  const [backDNI, setBackDNI] = useState<File | null>(null);
  const [profileImgFile, setProfileImgFile] = useState<File | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userService = useMemo(() => new UserService(), []);

  const userid = params.get("userid");
  const token = params.get("token");

  const validate = (data: FormDataValues) => {
    let errors: { [key: string]: string } = {};
    let isValid = true;

    const { birthDate, dni, cuit, cbu, phone } = data;

    if (!birthDate) {
      errors.birthDate = t("employee.verify.errors.birthDate");
      isValid = false;
    } else {
      const birthDateObj = new Date(birthDate);
      const today = new Date();
      const age = today.getFullYear() - birthDateObj.getFullYear();

      //Verificación si todavia no cumple este año
      const isBeforeBirthdayThisYear =
        today.getMonth() < birthDateObj.getMonth() ||
        (today.getMonth() === birthDateObj.getMonth() &&
          today.getDate() < birthDateObj.getDate());

      if (age < 18 || (age === 18 && isBeforeBirthdayThisYear)) {
        errors.birthDate = t("employee.verify.errors.elderAge");
        isValid = false;
      }
    }

    if (!phone || phone.length < 8 || phone.length > 15) {
      errors.phone = t("employee.verify.errors.phone");
      isValid = false;
    }

    if (!dni || dni.length !== 8) {
      errors.dni = t("employee.verify.errors.dni");
      isValid = false;
    }

    if (!cuit || cuit.length !== 11) {
      errors.cuit = t("employee.verify.errors.cuit");
      isValid = false;
    }

    if (!cbu) {
      errors.cbu = t("employee.verify.errors.cbu");
      isValid = false;
    } else {
      const cleanValue = cbu.trim().replace(/\s/g, "");

      // Si es CBU debe tener 22 digitos
      const isCBU = /^\d{22}$/.test(cleanValue);

      // Alias debe tener entre 6 y 20 caracteres y alfanumerico
      const isAlias = /^[a-zA-Z0-9.]{6,20}$/.test(cleanValue);

      if (!isCBU && !isAlias) {
        errors.cbu = t("employee.verify.errors.invalid-cbu-alias");
        isValid = false;
      }
    }

    if (!frontDNI || !backDNI) {
      errors.dniDocuments = t("employee.verify.errors.id-photos");
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTermsChecked((prev) => !prev);
  };

  const handleAcceptTerms = () => {
    setIsTermsChecked(true);
    setIsOpenModal(false);
  };

  const getAvatarSrc = () => {
    if (profileImgFile) {
      return URL.createObjectURL(profileImgFile);
    } else if (employee?.imageUrl) {
      return employee?.imageUrl;
    } else {
      return "https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg=";
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsVerifying(true);

    const formData = new FormData(e.currentTarget);
    const data: FormDataValues = {
      birthDate: formData.get("birthDate")?.toString() || "",
      dni: formData.get("dni")?.toString() || "",
      cuit: formData.get("cuit")?.toString() || "",
      cbu: formData.get("cbu")?.toString() || "",
      phone: formData.get("phone")?.toString() || "",
      frontDNI: frontDNI,
      backDNI: backDNI,
    };

    if (!validate(data)) {
      setIsVerifying(false);
      return;
    }

    const compressedFrontDNI = await compressImage(frontDNI!);
    const compressedBackDNI = await compressImage(backDNI!);
    const compressedProfileImg = profileImgFile
      ? await compressImage(profileImgFile)
      : null;

    await userService.verifyEmployee(
      {
        UserId: Number(userid!),
        Token: token!,
        BirthDate: data.birthDate,
        CbuCvu: data.cbu,
        Cuit: data.cuit,
        Dni: data.dni,
        Phone: data.phone,
        DocumentFront: compressedFrontDNI,
        DocumentBack: compressedBackDNI,
        PhotoImage: compressedProfileImg,
      },
      (data) => {
        setIsVerifying(false);
        navigate("/verified-employee", {
          state: {
            userId: userid,
            useQR: employee?.useQR,
            employeeName: employee?.name,
          },
        });
      },
      (error) => {
        setIsVerifying(false);
        showToast(error, "error");
      }
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchEmployee = async () => {
      setIsLoading(true);

      try {
        await userService.getUnverifiedUser(
          {
            token: token!,
            userid: Number(userid!),
          },
          (data) => {
            setEmployee(data);
          },
          (error) => {
            setErrors({ verified: "Usuario verificado" });
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    if (userid && token) {
      fetchEmployee();
    }
  }, [userService, showToast, token, userid]);

  useEffect(() => {
    let objectUrl: string | null = null;

    if (profileImgFile) {
      objectUrl = URL.createObjectURL(profileImgFile);
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [profileImgFile]);

  return (
    <main className="h-screen relative container mx-auto">
      <Link to="/" className="absolute top-6 left-6">
        <img width={62} height={62} src={DivixLogo} alt="Divix Logo" />
      </Link>
      <div className="flex flex-col mx-auto justify-center items-center pt-24 pb-4">
        <img
          className="absolute top-6 right-6 md:static h-8 w-8 md:w-14 md:h-14"
          src={Logo}
          alt="Divix Logo"
        />
        {!!errors.verified && !isLoading && (
          <div className="mt-20 text-xl text-center font-semibold">
            <p>{errors.verified}</p>
          </div>
        )}
        {!errors.verified && isLoading && (
          <div className="mt-20 text-xl font-semibold">{t("loading")}</div>
        )}
        {!errors.verified && employee && !isLoading && (
          <>
            <form
              className="flex flex-col md:flex-row gap-12 md:gap-24 items-center max-w-sm md:max-w-4xl mt-8 w-full"
              id="verify-employee"
              onSubmit={handleSubmit}
            >
              <div className="flex-1 px-4 md:px-0 flex justify-center md:justify-end">
                <div className="flex flex-row justify-between items-center  md:flex md:flex-col md:items-start md:justify-center gap-5">
                  <div className="text-2xl h-fit relative">
                    <Avatar
                      sx={{
                        width: {
                          xs: "4rem",
                          md: "6rem",
                        },
                        height: {
                          xs: "4rem",
                          md: "6rem",
                        },
                        border: "1px solid #E0E0E0",
                      }}
                      src={getAvatarSrc()}
                      alt="Avatar Usuario"
                    />
                    <ChangeAvatar
                      employee={employee}
                      onConfirm={(file) => setProfileImgFile(file)}
                    />
                  </div>
                  <div className="flex flex-col w-full max-w-60 md:max-w-full md:gap-5">
                    <div className="pt-2 text-sm md:text-lg font-medium break-words md:break-normal">
                      {employee.name}
                    </div>
                    <div className="text-sm md:text-lg font-medium break-words md:break-normal">
                      {employee.email}
                    </div>
                    <div className="text-sm md:text-lg font-medium">
                      {employee.groupName ?? "Sin grupo"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:block h-52 w-[2px] shrink-0 bg-primary " />
              <div className="w-[75%] h-[2px] shrink-0 bg-primary md:hidden" />
              <div className="px-4 md:px-0 flex-1 text-center mt-8">
                <TextField
                  label={t("employee.verify.birthDate")}
                  variant="standard"
                  error={!!errors.birthDate}
                  helperText={errors.birthDate}
                  fullWidth
                  name="birthDate"
                  type="date"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#000",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#838383",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#838383",
                      fontWeight: "300",
                      fontFamily: "Montserrat",
                      fontSize: "1rem",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
                <TextField
                  label={t("employee.verify.phone")}
                  type="text"
                  name="phone"
                  error={!!errors.phone}
                  helperText={errors.phone}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    let value = input.value;

                    value = value.replace(/[^\d\s+]/g, ""); // Permitimos numeros, espacios y +
                    value = value.replace(/\s+/g, " "); // No permitimos mas de 1 espacio consecutivo

                    // Si ingresa + que sea lo primero de la cadena
                    value = value.replace(/(?!^)\+/g, "");
                    if (value.includes("+") && value[0] !== "+") {
                      value = "+" + value.replace(/\+/g, "");
                    }

                    input.value = value;
                  }}
                  variant="standard"
                  fullWidth
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#000",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#838383",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#838383",
                      fontWeight: "300",
                      fontFamily: "Montserrat",
                      fontSize: "1rem",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
                <TextField
                  label={t("employee.verify.dni")}
                  name="dni"
                  error={!!errors.dni}
                  helperText={errors.dni}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    input.value = input.value.replace(/[^0-9]/g, "");
                  }}
                  variant="standard"
                  fullWidth
                  type="number"
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#000",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#838383",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#838383",
                      fontWeight: "300",
                      fontFamily: "Montserrat",
                      fontSize: "1rem",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
                <TextField
                  label={t("employee.verify.cuit")}
                  name="cuit"
                  error={!!errors.cuit}
                  helperText={errors.cuit}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    input.value = input.value.replace(/[^0-9]/g, "");
                  }}
                  variant="standard"
                  fullWidth
                  type="number"
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#000",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#838383",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#838383",
                      fontWeight: "300",
                      fontFamily: "Montserrat",
                      fontSize: "1rem",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
                <TextField
                  label={t("employee.verify.cbu")}
                  name="cbu"
                  error={!!errors.cbu}
                  helperText={errors.cbu}
                  variant="standard"
                  fullWidth
                  type="text"
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    // Sacamos caracteres especiales
                    input.value = input.value.replace(/[^a-zA-Z0-9.-]/g, "");
                  }}
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#000",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#838383",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#838383",
                      fontWeight: "300",
                      fontFamily: "Montserrat",
                      fontSize: "1rem",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
                <div className="w-full mt-4">
                  <DNIUploader
                    onFilesChange={(front, back) => {
                      setFrontDNI(front);
                      setBackDNI(back);
                    }}
                  />
                  {errors.dniDocuments && (
                    <p className="text-red mt-2 text-xs">
                      {errors.dniDocuments}
                    </p>
                  )}
                </div>
              </div>
            </form>
            <div className="w-full mx-auto mt-4">
              <div className="w-full flex justify-center gap-1 items-center text-center mx-auto mt-8 px-2">
                <Checkbox
                  checked={isTermsChecked}
                  onChange={handleCheckboxChange}
                  color="primary"
                  sx={{
                    color: "#000",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#EF0BB8",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                      margin: 0,
                      padding: 0,
                    },
                  }}
                />
                <div className="text-xs font-semibold">
                  {t("verify-employee.accept")}{" "}
                  <span
                    className="hover:underline cursor-pointer"
                    onClick={() => setIsOpenModal(true)}
                  >
                    {t("verify-employee.terms")}
                  </span>
                </div>
              </div>
              <div className="flex justify-center  items-center mt-8">
                <button
                  type="submit"
                  form="verify-employee"
                  disabled={!isTermsChecked || isVerifying}
                  className={`mx-auto leading-5 bg-black   text-white font-light rounded-3xl py-1 px-24 disabled:opacity-50 ${
                    isTermsChecked
                      ? "hover:bg-black/90 opacity-100"
                      : "opacity-50"
                  }`}
                >
                  {isVerifying ? (
                    <CircularProgress
                      sx={{
                        color: "#fff",
                      }}
                      size="1.5rem"
                      className="animate-spin "
                    />
                  ) : (
                    t("employee.verify.submit")
                  )}
                </button>
              </div>
              <p className="pt-4 text-center opacity-70 text-xs font-medium max-w-xl mx-auto">
                {t("verify-employee.note")}
              </p>
            </div>
          </>
        )}
      </div>
      {isOpenModal && (
        <AcceptTermsModal
          onAccept={handleAcceptTerms}
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
        />
      )}
    </main>
  );
}
