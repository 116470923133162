import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Legend } from "./legend";
import { DashboardFilter } from "./dashboard-filter";
import { useEffect, useMemo, useState } from "react";
import { Chart, DashboardFilterOption } from "../../types";
import { DashboardService } from "../../services/dashboard-service";
import { useToast } from "../../context/toast-context";
import { GraphMetricsSkeleton } from "../skeletons/graph-metrics-skeleton";
import { CustomTooltip } from "./custom-tooltip";
import { CustomDot } from "./custom-dot";

type FixedDomainConfig = {
  domain: [number, number];
  ticks: number[];
};

type AutoDomainConfig = {
  domain: [number, "auto"];
  ticks: undefined;
};

type AxisConfig = FixedDomainConfig | AutoDomainConfig;

interface ChartsMetricsProps {
  startDate: string;
  endDate: string;
}

export function ChartMetrics({ endDate, startDate }: ChartsMetricsProps) {
  const [filter, setFilter] = useState<DashboardFilterOption>({
    timeUnit: "hours",
    calculationType: "average",
  });
  const [chartData, setChartData] = useState<Chart[]>([]);
  const [loading, setLoading] = useState(false);

  const { showToast } = useToast();
  const dashboardService = useMemo(() => new DashboardService(), []);

  const handleFilterChange = (newFilter: DashboardFilterOption) => {
    setFilter(newFilter);
  };

  const formatValoracionesAxis = (value: number) => {
    return value.toString();
  };
  const formatPropinasAxis = (value: number) => {
    return value.toFixed(1) + "k";
  };

  const getValoracionesAxisConfig = useMemo((): AxisConfig => {
    if (filter.calculationType === "average") {
      return {
        domain: [0, 5],
        ticks: [0, 1, 2, 3, 4, 5],
      };
    }

    return {
      domain: [0, "auto"],
      ticks: undefined,
    };
  }, [filter.calculationType]);

  useEffect(() => {
    const fetchChart = async () => {
      setLoading(true);
      await dashboardService.getChartMetrics(
        {
          fechadesde: startDate,
          fechahasta: endDate,
          time: filter.timeUnit,
          calculationType: filter.calculationType,
        },
        (data) => {
          const processedData = data.data.map((item) => ({
            ...item,
            propinas:
              typeof item.propinas === "string"
                ? parseFloat(item.propinas)
                : item.propinas,
            valoraciones:
              typeof item.valoraciones === "string"
                ? parseFloat(item.valoraciones)
                : item.valoraciones,
          }));
          setChartData(processedData);
        },
        (error) => showToast(error, "error")
      );
      setLoading(false);
    };

    fetchChart();
  }, [dashboardService, endDate, filter, showToast, startDate]);

  if (loading) {
    return <GraphMetricsSkeleton />;
  }

  return (
    <div className="flex flex-col h-full w-full bg-white rounded-lg p-4">
      <div className="flex justify-between pb-4">
        <Legend />
        <DashboardFilter value={filter} onChange={handleFilterChange} />
      </div>
      <div className="flex gap-2 flex-grow">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            margin={{ top: 10, right: 30, left: 20, bottom: 5 }}
            data={chartData}
          >
            <XAxis dy={12} className="text-sm font-semibold" dataKey="time" />
            <YAxis
              yAxisId="left"
              orientation="left"
              tickFormatter={formatValoracionesAxis}
              className="text-sm font-semibold"
              domain={getValoracionesAxisConfig.domain}
              ticks={getValoracionesAxisConfig.ticks}
              stroke="#F3C433"
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={formatPropinasAxis}
              domain={[0, "auto"]}
              className="text-sm font-semibold"
              stroke="#EF0BB8"
              axisLine={false}
              tickLine={false}
            />
            <CartesianGrid vertical={false} />
            <Tooltip content={<CustomTooltip filter={filter} />} />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="valoraciones"
              stroke="#F3C433"
              strokeWidth="2px"
              name="Valoraciones"
              activeDot={{ r: 8 }}
              dot={(props) => {
                const { key, ...rest } = props;
                return <CustomDot key={key} {...rest} />;
              }}
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="propinas"
              stroke="#EF0BB8"
              strokeWidth="2px"
              name="Propinas"
              activeDot={{ r: 8 }}
              dot={(props) => {
                const { key, ...rest } = props;
                return <CustomDot key={key} {...rest} />;
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
