import { useLocation } from "react-router-dom";
import Logo from "../assets/img/logos/X.svg";
import { useTranslation } from "react-i18next";
import { DownloadPDF } from "../components/DownloadPDF";

interface EmployeeState {
  userId: number;
  useQR: boolean;
  employeeName: string;
}

export function VerifiedEmployee() {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as EmployeeState | undefined;

  return (
    <div className="relative h-screen">
      <main className="container mx-auto">
        <div className="flex flex-col mx-auto justify-center items-center pt-32">
          <img className="w-14 h-14" src={Logo} alt="Divix Logo" />
          <h1 className="font-normal text-center tracking-wide text-5xl pt-14">
            {t("account-verified.employee.ready")}
          </h1>

          <h2 className="font-normal text-center tracking-wide max-w-md text-2xl pt-6">
            {t("account-verified.employee.title")}
          </h2>

          {state?.useQR && (
            <>
              <p className="py-10 line-clamp-2 text-center font-medium max-w-sm">
                {t("account-verified.employee.subtitle")}
              </p>
              <DownloadPDF
                userId={state.userId}
                employeeName={state.employeeName}
                variant="default"
              />

              <p className="pt-10 line-clamp-2 text-center font-medium max-w-md">
                {t("account-verified.employee.recordatory")}
              </p>
              <p className="pt-4 line-clamp-2 text-center text-primary font-medium">
                {t("account-verified.employee.footer")}
              </p>
            </>
          )}
        </div>
      </main>
    </div>
  );
}
