import { CircularProgress } from "@mui/material";

export function InfinityScrollLoader() {
  return (
    <div className="flex items-center justify-center">
      <CircularProgress
        sx={{
          color: "#30302f",
        }}
        className="animate-spin h-6 w-6 "
      />
    </div>
  );
}
