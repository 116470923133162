import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { DashboardFilterOption } from "../../types";
import { useTranslation } from "react-i18next";

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  active?: boolean;
  payload?: Array<{
    value: number;
    dataKey: string;
    name: string;
  }>;
  label?: string;
  filter: DashboardFilterOption;
}

export function CustomTooltip({
  active,
  payload,
  label,
  filter,
}: CustomTooltipProps) {
  const { t } = useTranslation();

  if (!active || !payload || !payload.length) return null;

  const valoraciones = payload[0].value ?? 0;
  const propinas = payload[1].value ?? 0;
  const calculationTypeLabel =
    filter.calculationType === "average"
      ? t("chart.filter.average")
      : t("chart.filter.total");

  return (
    <div className="bg-[#6f6f6e] w-full py-1 px-4 text-start border text-white border-gray-200 rounded-3xl shadow-lg">
      {filter.timeUnit === "hours" && (
        <p className="font-medium pb-2">
          {`${t("chart.filter.hour")}: ${label}:00hs`} - {calculationTypeLabel}
        </p>
      )}
      {filter.timeUnit === "month" && (
        <p className="font-medium pb-2">
          {`${t("chart.filter.month")}: ${label}`} - {calculationTypeLabel}
        </p>
      )}
      {filter.timeUnit === "day" && (
        <p className="font-medium pb-2">
          {`${t("chart.filter.day")}: ${label}`} - {calculationTypeLabel}
        </p>
      )}
      <p className="flex items-center text-[#d9d9d9] font-semibold">
        <span className="w-4 h-4 rounded-md bg-[#f3c433] mr-2"></span>
        {`${t("chart.ratings")}: ${valoraciones}`}
      </p>
      <p className="flex items-center text-[#d9d9d9] font-semibold">
        <span className="w-4 h-4 rounded-md bg-[#ef0bb8] mr-2"></span>
        {`${t("chart.tips")}: $${propinas}`}
      </p>
    </div>
  );
}
