import { Rating, styled } from "@mui/material";
import Star from "../icons/Star";
import { useEffect, useMemo, useState } from "react";
import { DashboardService } from "../../services/dashboard-service";
import { useToast } from "../../context/toast-context";
import { TipByBranch } from "../../services/dto/response/dashboard-response-dto";
import { BranchesMetricsSkeleton } from "../skeletons/branches-metrics-skeleton";

const StyledRating = styled(Rating)(() => ({
  "& .MuiRating-iconFilled": {
    color: "#f3c433",
  },
  "& .MuiRating-iconHover": {
    color: "#f3c433",
  },
}));

interface BranchesMetricsProps {
  startDate: string;
  endDate: string;
}

export function BranchesMetrics({ endDate, startDate }: BranchesMetricsProps) {
  const [data, setData] = useState<TipByBranch[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { showToast } = useToast();
  const dashboardService = useMemo(() => new DashboardService(), []);

  useEffect(() => {
    const fetchBranchMetrics = async () => {
      if (!startDate || !endDate) return;
      setIsLoading(true);
      await dashboardService.getBranchesMetrics(
        {
          fechadesde: startDate,
          fechahasta: endDate,
        },
        (data) => {
          setData(data.tipsAndRatingsByBranch);
          setIsLoading(false);
        },
        (error) => {
          showToast(error, "error");
          setIsLoading(false);
        }
      );
    };
    fetchBranchMetrics();
  }, [dashboardService, showToast, startDate, endDate]);

  if (isLoading) {
    return <BranchesMetricsSkeleton />;
  }

  return (
    <ul className="flex gap-4 p-2 h-full">
      {data.map((item) => (
        <li
          key={item.branchId}
          className="flex-shrink-0 w-72  p-2 bg-[#e9ebf2] rounded-md"
        >
          <article className="flex flex-col items-start gap-1">
            <div className="w-full flex items-center justify-stretch text-[#727272]">
              <StyledRating
                name="rating"
                value={Number(item.promedio_rating)}
                precision={0.1}
                readOnly
                size="small"
                icon={<Star />}
                emptyIcon={<Star />}
                sx={{
                  pointerEvents: "none",
                }}
              />
              <span className="text-xl mx-2">
                {Number(item.promedio_rating).toFixed(2)}
              </span>
              <span className="text-sm font-light ">({item.valoraciones})</span>
            </div>
            <div className="font-bold text-2xl text-[#727272]">
              ${item.propinas_total}
            </div>
            <span className="text-[#727272] text-sm ">
              Propina promedio ${item.propinas_promedio}
            </span>
            <div
              role="none"
              className="w-full h-[1px] shrink-0 bg-gray-light mb-2"
            />
            <span
              title={item.branchName}
              className="tracking-wide line-clamp-1 text-md text-[#727272] font-light"
            >
              {item.branchName}
            </span>
          </article>
        </li>
      ))}
    </ul>
  );
}
