import { useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  Crop,
  PixelCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { setCanvasPreview } from "../utils/set-canvas-preview";
import { useTranslation } from "react-i18next";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

interface ImageCropperProps {
  onCropChange: (file: File | null) => void;
  employeeName: string;
}

export function ImageCropper({
  onCropChange,
  employeeName,
}: ImageCropperProps) {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imgSrc, setImgSrc] = useState<string>("");
  const [crop, setCrop] = useState<Crop | undefined>(undefined);
  const [error, setError] = useState<string>("");
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const { t } = useTranslation();

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // Centra el recorte en la imagen con el tamaño mínimo
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (ASPECT_RATIO) {
      const { width, height } = e.currentTarget;
      const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

      const crop = makeAspectCrop(
        {
          unit: "%",
          width: cropWidthInPercent,
        },
        ASPECT_RATIO,
        width,
        height
      );
      const centeredCrop = centerCrop(crop, width, height);
      setCrop(centeredCrop);
    }
  }

  const generateCroppedFile = async () => {
    if (imgRef.current && previewCanvasRef.current && crop) {
      const pixelCrop = convertToPixelCrop(
        crop as Crop,
        imgRef.current.width,
        imgRef.current.height
      ) as PixelCrop;

      setCanvasPreview(imgRef.current, previewCanvasRef.current, pixelCrop);

      previewCanvasRef.current.toBlob(
        (blob) => {
          if (blob) {
            const file = new File([blob], `${employeeName}.jpeg`, {
              type: "image/jpeg",
            });
            onCropChange(file);
          }
        },
        "image/jpeg",
        1
      );
    } else {
      setError("No se pudo generar el archivo");
      return;
    }
  };

  return (
    <>
      <label className="block mb-3 w-full mx-auto">
        <span className="sr-only">{t("images.update-avatar.label")}</span>
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          className="block w-full text-sm text-slate-500 file:mr-4 file:py-1 file:px-2 file:rounded-full file:border-0 file:text-xs file:bg-[#2d2f32] file:text-white hover:file:bg-[#2d2f32]/90"
        />
      </label>
      {error && <p className="text-red text-xs">{error}</p>}
      {imgSrc && (
        <div className="flex flex-col items-center">
          {!!imgSrc && (
            <ReactCrop
              crop={crop as Crop}
              onChange={(_, percentCrop) => {
                setCrop(percentCrop);
              }}
              onComplete={(c) => {
                setCompletedCrop(c);
                if (
                  c?.width &&
                  c?.height &&
                  imgRef.current &&
                  previewCanvasRef.current
                ) {
                  setCanvasPreview(imgRef.current, previewCanvasRef.current, c);
                  generateCroppedFile();
                }
              }}
              circularCrop
              locked
              aspect={ASPECT_RATIO}
              minWidth={MIN_DIMENSION}
            >
              <img
                ref={imgRef}
                src={imgSrc}
                alt="Upload"
                style={{ maxHeight: "100vh" }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
      )}
      {!!completedCrop && (
        <canvas
          ref={previewCanvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 150,
            height: 150,
          }}
        />
      )}
    </>
  );
}
