import {
  FormHelperText,
  IconButton,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Branch } from "../../types";
import { BranchService } from "../../services/branch-service";
import React, { useMemo, useState } from "react";
import { useToast } from "../../context/toast-context";
import { ButtonForm } from "./button-form";
import InfoIcon from "../icons/InfoIcon";
import { refactorLink } from "../../utils/links";

interface AddBranchWithTipForm {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  addBranch?: (newBranch: Branch) => void;
}

export function AddBranchWithSplitForm({
  setIsModalOpen,
  addBranch,
}: AddBranchWithTipForm) {
  const { t } = useTranslation();
  const branchService = useMemo(() => new BranchService(), []);
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [sameAmount, setSameAmount] = useState(false);
  const [googleReviewLink, setGoogleReviewLink] = useState("");
  const [onlyRatings, setOnlyRatings] = useState(false);

  const [errors, setErrors] = useState({
    name: "",
    address: "",
    phone: "",
  });

  const validate = (name: string, address: string, phone: string) => {
    let errors = {
      name: "",
      address: "",
      phone: "",
    };
    let isValid = true;

    if (!name) {
      errors.name = t("branches.create.errors.name");
      isValid = false;
    }

    if (!address) {
      errors.address = t("branches.create.errors.address");
      isValid = false;
    }

    if (!phone) {
      errors.phone = t("branches.create.errors.phone");
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get("name") as string;
    const address = formData.get("address") as string;
    const phone = formData.get("phone") as string;

    const updatedReviewLink = refactorLink(googleReviewLink);

    if (!validate(name, address, phone)) {
      setLoading(false);
      return;
    }
    await branchService.addBranch(
      {
        Address: address,
        Name: name,
        Phone: phone,
        EqualSplitTip: sameAmount,
        GoogleLink: updatedReviewLink,
        OnlyRatings: onlyRatings,
      },
      (data) => {
        // Para actualizar la UI en pantalla branches
        addBranch &&
          addBranch({
            employeeCount: 0,
            name: data.name,
            active: data.active,
            address: data.address,
            phone: data.phone,
            branchId: data.branchId,
            companyId: data.companyId,
            equalSplitTip: data.equalSplitTip,
            googleLink: data.googleLink,
            onlyRatings: data.onlyRatings,
          });
        showToast(t("branches.create.success"), "success");
      },
      (error) => {
        showToast(error, "error");
      }
    );
    setLoading(false);
    setIsModalOpen && setIsModalOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid px-6 gap-4">
          <TextField
            type="text"
            name="name"
            error={!!errors.name}
            helperText={errors.name}
            label={t("branches.create.name")}
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
          <TextField
            type="text"
            name="address"
            error={!!errors.address}
            helperText={errors.address}
            label={t("branches.create.address")}
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />

          <TextField
            type="text"
            name="phone"
            error={!!errors.phone}
            helperText={errors.phone}
            label={t("branches.create.phone")}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              let value = input.value;

              value = value.replace(/[^\d\s+]/g, ""); // Permitimos numeros, espacios y +
              value = value.replace(/\s+/g, " "); // No permitimos mas de 1 espacio consecutivo

              // Si ingresa + que sea lo primero de la cadena
              value = value.replace(/(?!^)\+/g, "");
              if (value.includes("+") && value[0] !== "+") {
                value = "+" + value.replace(/\+/g, "");
              }

              input.value = value;
            }}
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col ">
              <label htmlFor="equalSplit">
                {t("branches.create.with-equal-split")}
              </label>
              <FormHelperText
                sx={{
                  fontFamily: "Montserrat",
                }}
              >
                {t("branches.edit.split-helper")}
              </FormHelperText>
            </div>
            <Switch
              id="equalSplit"
              name="equalSplit"
              checked={sameAmount}
              onChange={(e) => setSameAmount(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                  bgcolor: "#EF0BB8",
                },
                "& .MuiSwitch-track": {
                  bgcolor: "#898989",
                },
                "& .MuiSwitch-thumb": {
                  bgcolor: "#EF0BB8",
                },
              }}
            />
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col ">
              <label htmlFor="qr">{t("branches.create.only-review")}</label>
              <FormHelperText
                sx={{
                  fontFamily: "Montserrat",
                }}
              >
                {t("branches.create.only-review-note")}
              </FormHelperText>
            </div>
            <Switch
              id="qr"
              name="qr"
              checked={onlyRatings}
              onChange={(e) => setOnlyRatings(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                  bgcolor: "#EF0BB8",
                },
                "& .MuiSwitch-track": {
                  bgcolor: "#898989",
                },
                "& .MuiSwitch-thumb": {
                  bgcolor: "#EF0BB8",
                },
              }}
            />
          </div>

          <div className="w-full flex flex-col">
            <TextField
              type="text"
              fullWidth
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              placeholder="https://g.page/your-business/review"
              label={
                <div>
                  {t("branches.create.google.title")}
                  <Tooltip
                    title={
                      <p style={{ fontFamily: "Montserrat" }}>
                        {t("branches.create.google.tooltip")}
                      </p>
                    }
                    placement="top"
                  >
                    <IconButton>
                      <InfoIcon
                        sx={{
                          fill: "#fff",
                          height: 16,
                          width: 16,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              }
              value={googleReviewLink ?? ""}
              onChange={(e) => setGoogleReviewLink(e.target.value)}
              variant="standard"
              sx={{
                "& .MuiInput-root": {
                  color: "#000",
                  fontFamily: "Montserrat",
                  "&:before": {
                    borderBottom: "1px solid #838383",
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#898989",
                  },
                },
                "& label.Mui-focused": {
                  color: "#7e7e7e",
                },
                "& .MuiInputLabel-standard": {
                  color: "#000",
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#838383",
                },
              }}
            />
            <a
              href="https://support.google.com/business/answer/3474122?hl=es-419"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline mt-4 text-sm w-fit"
            >
              {t("branches.create.google.link")}
            </a>
          </div>
        </div>

        <div className="flex justify-center mt-8 mx-6">
          <ButtonForm loading={loading}>
            {t("branches.create.submit")}
          </ButtonForm>
        </div>
      </form>
    </>
  );
}
