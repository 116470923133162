import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { ImageCropper } from "../ImageCropper";
import { UnverifiedUser } from "../../services/dto/response/user-response-dto";
import { useTranslation } from "react-i18next";

interface ChangeAvatarProps {
  employee: UnverifiedUser;
  onConfirm: (file: File) => void;
}

export function ChangeAvatar({ employee, onConfirm }: ChangeAvatarProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [croppedFile, setCroppedFile] = useState<File | null>(null);

  const { t } = useTranslation();

  const handleConfirm = () => {
    if (croppedFile) {
      onConfirm(croppedFile);
      setOpenDialog(false);
    }
  };

  return (
    <>
      <IconButton
        onClick={() => setOpenDialog(true)}
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          borderRadius: "50%",
          width: "1.5rem",
          height: "1.5rem",
          padding: 0,
          outline: "none",
          border: "none",
        }}
        aria-label="Cambiar foto"
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10" fill="#EF0BB8" />
          <path
            d="M12 7V17M7 12H17"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </IconButton>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setCroppedFile(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{
            fontFamily: "Montserrat",
          }}
        >
          {t("images.update-avatar.label")}
        </DialogTitle>
        <DialogContent className="custom-scrollbar">
          <ImageCropper
            employeeName={employee.name}
            onCropChange={(file) => setCroppedFile(file)}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: "10px 20px",
          }}
        >
          <Button
            sx={{
              fontFamily: "Montserrat",
              color: "#000",
              fontSize: "0.875rem",
              textTransform: "capitalize",
              fontWeight: 300,
            }}
            onClick={() => {
              setOpenDialog(false);
            }}
            color="inherit"
          >
            {t("employee.delete.cancel")}
          </Button>
          <Button
            sx={{
              fontFamily: "Montserrat",
              backgroundColor: "#EF0BB8",
              color: "#fff",
              fontSize: "0.875rem",
              textTransform: "capitalize",
              fontWeight: 300,
              "&.Mui-disabled": {
                opacity: "40%",
                color: "#fff",
              },
            }}
            disabled={!croppedFile}
            onClick={handleConfirm}
          >
            {t("groups.select.submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
