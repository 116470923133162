import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services/user-service";
import { useToast } from "../../context/toast-context";
import { ImageCropper } from "../ImageCropper";
import { EmployeeUser } from "../../types";
import { compressImage } from "../../utils/compress-image";

interface AvatarUpdateByManagerProps {
  employee: EmployeeUser;
  onAvatarChange: (imageUrl: string) => void;
}

export function AvatarUpdateByManager({
  employee,
  onAvatarChange,
}: AvatarUpdateByManagerProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [croppedFile, setCroppedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const userService = useMemo(() => new UserService(), []);
  const { t } = useTranslation();
  const { showToast } = useToast();

  const handleUpload = async () => {
    if (!croppedFile) return;
    setIsLoading(true);
    const compressFile = await compressImage(croppedFile);
    await userService.uploadAvatar(
      {
        file: compressFile,
        userId: employee.userId,
      },
      (data) => {
        onAvatarChange(data.imageUrl);
        showToast(t("images.update-avatar.success"), "success");
      },
      (error) => {
        showToast(error, "error");
      }
    );
    setOpenDialog(false);
    setIsLoading(false);
  };

  return (
    <>
      <IconButton
        onClick={() => setOpenDialog(true)}
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          borderRadius: "50%",
          width: "1.5rem",
          height: "1.5rem",
          padding: 0,
          outline: "none",
          border: "none",
        }}
        aria-label={t("images.update-avatar.label")}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10" fill="#EF0BB8" />
          <path
            d="M12 7V17M7 12H17"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </IconButton>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setCroppedFile(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{
            fontFamily: "Montserrat",
          }}
        >
          {t("images.update-avatar.label")}
        </DialogTitle>
        <DialogContent className="custom-scrollbar">
          <ImageCropper
            employeeName={employee.name}
            onCropChange={(file) => setCroppedFile(file)}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: "10px 20px",
          }}
        >
          <Button
            disabled={isLoading}
            sx={{
              fontFamily: "Montserrat",
              color: "#000",
              fontSize: "0.875rem",
              textTransform: "capitalize",
              fontWeight: 300,
            }}
            onClick={() => {
              setOpenDialog(false);
            }}
            color="inherit"
          >
            {t("employee.delete.cancel")}
          </Button>
          <Button
            sx={{
              fontFamily: "Montserrat",
              backgroundColor: "#EF0BB8",
              color: "#fff",
              fontSize: "0.875rem",
              textTransform: "capitalize",
              fontWeight: 300,
              "&.Mui-disabled": {
                opacity: "40%",
                color: "#fff",
              },
            }}
            disabled={isLoading || !croppedFile}
            onClick={handleUpload}
          >
            {!isLoading ? (
              <p>{t("images.update-avatar.upload")}</p>
            ) : (
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1.5rem"
                className="animate-spin "
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
