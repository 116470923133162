import { useState } from "react";
import { BranchesMetrics } from "../components/dashboard/branches-metrics";
import { OverallRating } from "../components/dashboard/overall-rating";
import { DateFilter } from "../components/messages/date-filter";
import { EmployeesMetrics } from "../components/dashboard/employees-metrics";
import { ChartMetrics } from "../components/dashboard/chart-metrics";
import { getLastWeek } from "../utils/get-last-week";

export function Dashboard() {
  const { startDate: defaultStartDate, endDate: defaultEndDate } =
    getLastWeek();

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const validateDate = (date: string | null, fallback: string) => {
    return date || fallback;
  };

  return (
    <main className="h-full flex overflow-hidden">
      {/* Contenido principal de 2 columnas */}
      <div className="flex w-full gap-2">
        {/* primer columna */}
        <div className="flex flex-col flex-grow gap-6 max-w-[70%]">
          {/* valoraciones */}
          <div className="w-full h-24 rounded-lg bg-primary text-white">
            <OverallRating
              startDate={validateDate(startDate, defaultStartDate)}
              endDate={validateDate(endDate, defaultEndDate)}
            />
          </div>

          {/* graficos */}
          <div className="flex-grow bg-white text-black rounded-lg">
            <ChartMetrics
              startDate={validateDate(startDate, defaultStartDate)}
              endDate={validateDate(endDate, defaultEndDate)}
            />
          </div>

          {/* ratings con scroll horizontal */}
          <section className="h-48 bg-white rounded-lg overflow-x-auto custom-scrollbar-light">
            <div className="flex justify-start  h-full mr-2">
              <BranchesMetrics
                startDate={validateDate(startDate, defaultStartDate)}
                endDate={validateDate(endDate, defaultEndDate)}
              />
            </div>
          </section>
        </div>

        {/* segunda columna */}
        <div className="w-[30%]  flex flex-col gap-6">
          {/* filtro de fechas */}
          <div className="bg-white text-black rounded-lg">
            <div className="p-2 flex justify-center items-center h-full">
              <DateFilter
                size="sm"
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>

          {/* resumen de empleados con scroll vertical */}
          <div className="flex-grow bg-[#a0abba] text-white rounded-lg overflow-y-auto custom-scrollbar-light">
            <EmployeesMetrics
              startDate={validateDate(startDate, defaultStartDate)}
              endDate={validateDate(endDate, defaultEndDate)}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
