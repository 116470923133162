import Axios from "axios";
import { useAuth } from "../context/auth-context";

export function RequestInterceptor() {
  const { logout } = useAuth();
  const getToken = () => {
    if (typeof window !== "undefined") {
      const token = window.localStorage.getItem("token");
      return token;
    }

    return undefined;
  };
  Axios.interceptors.request.use((config) => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  Axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        logout();
      }
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();
      } else {
        console.log(error);
      }
      return Promise.reject(error);
    }
  );
  return <></>;
}
