import imageCompression, { Options } from "browser-image-compression";

export async function compressImage(
  file: File,
  options?: Options
): Promise<File> {
  if (!file.type.startsWith("image/")) {
    return file;
  }

  const defaultOptions: Options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
    fileType: "image/jpeg",
    initialQuality: 0.8,
  };

  try {
    const compressedImage = await imageCompression(
      file,
      options || defaultOptions
    );

    const renamedFile = new File([compressedImage], file.name, {
      type: compressedImage.type,
    });

    return renamedFile;
  } catch (error) {
    return file;
  }
}
