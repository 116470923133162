import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CalendarIcon from "../icons/CalendarIcon";
import { DashboardFilterOption } from "../../types";
import { useTranslation } from "react-i18next";

interface DashboardFilterProps {
  value: DashboardFilterOption;
  onChange: (newValue: DashboardFilterOption) => void;
}

export function DashboardFilter({ onChange, value }: DashboardFilterProps) {
  const { t } = useTranslation();

  const handleTimeChange = (event: SelectChangeEvent) => {
    onChange({
      ...value,
      timeUnit: event.target.value as DashboardFilterOption["timeUnit"],
    });
  };

  const handleCalculationTypeChange = (event: SelectChangeEvent) => {
    onChange({
      ...value,
      calculationType: event.target
        .value as DashboardFilterOption["calculationType"],
    });
  };
  return (
    <div className="flex flex-col lg:flex-row items-start lg:items-center gap-2">
      <RadioGroup
        row
        value={value.calculationType}
        onChange={handleCalculationTypeChange}
      >
        <FormControlLabel
          value="average"
          sx={{
            "& .MuiTypography-root": {
              fontFamily: "Montserrat",
            },
          }}
          control={
            <Radio
              size="small"
              sx={{
                color: "#666",
                padding: "0 0.2rem",
                "&.Mui-checked": {
                  color: "#EF0BB8",
                },
              }}
            />
          }
          label={
            <span className="text-sm text-gray-dark">
              {t("chart.filter.average")}
            </span>
          }
        />
        <FormControlLabel
          value="total"
          sx={{
            "& .MuiTypography-root": {
              fontFamily: "Montserrat",
            },
          }}
          control={
            <Radio
              size="small"
              sx={{
                color: "#666",
                padding: "0 0.2rem",
                "&.Mui-checked": {
                  color: "#EF0BB8",
                },
              }}
            />
          }
          label={
            <span className="text-sm text-gray-dark">
              {t("chart.filter.total")}
            </span>
          }
        />
      </RadioGroup>
      <div className="flex gap-2 items-center">
        <p className="uppercase font-bold text-gray-dark text-sm text-nowrap">
          {t("chart.filter.label")}
        </p>
        <FormControl fullWidth size="small" className="bg-white rounded">
          <Select
            value={value.timeUnit}
            onChange={handleTimeChange}
            className="text-sm custom-select"
            renderValue={(selected) => (
              <div className="flex items-center gap-2 w-28">
                <CalendarIcon
                  sx={{
                    fill: "#fff",
                  }}
                />
                <span>
                  {selected === "hours"
                    ? t("chart.filter.hour")
                    : selected === "day"
                    ? t("chart.filter.day")
                    : t("chart.filter.month")}
                </span>
              </div>
            )}
            sx={{
              fontFamily: "Montserrat",
              borderRadius: "14px",
              color: "#666 ",
            }}
          >
            <MenuItem
              sx={{
                fontFamily: "Montserrat",
                color: "#666 ",
              }}
              value="hours"
            >
              {t("chart.filter.hour")}
            </MenuItem>
            <MenuItem
              sx={{
                fontFamily: "Montserrat",
              }}
              value="day"
            >
              {t("chart.filter.day")}
            </MenuItem>
            <MenuItem
              sx={{
                fontFamily: "Montserrat",
              }}
              value="month"
            >
              {t("chart.filter.month")}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
