import BaseService from "./base-service";
import {
  ChartMetricReq,
  OverallRatingReq,
} from "./dto/request/dashboard-request-dto";
import { RequestDto } from "./dto/request/request-dto";
import {
  BranchMetricsRes,
  ChartMetricsRes,
  EmployeeMetricsRes,
  OverallRatingRes,
} from "./dto/response/dashboard-response-dto";

export class DashboardService extends BaseService {
  private resource = "/api/dashboard";

  async getOverallRating(
    params: OverallRatingReq,
    onSuccess: (data: OverallRatingRes) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/tipsandratings", params);
    await this.doGet<OverallRatingRes>(reqParams, onSuccess, onError);
  }

  async getBranchesMetrics(
    params: OverallRatingReq,
    onSuccess: (data: BranchMetricsRes) => void,
    onError: (erroMessage: string) => void
  ) {
    const reqParams = new RequestDto(
      this.resource + "/tipsratingsbybranch",
      params
    );
    await this.doGet<BranchMetricsRes>(reqParams, onSuccess, onError);
  }

  async getEmployeesMetrics(
    params: OverallRatingReq,
    onSuccess: (data: EmployeeMetricsRes) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(
      this.resource + "/tipsratingsbyemployee",
      params
    );
    await this.doGet<EmployeeMetricsRes>(reqParams, onSuccess, onError);
  }

  async getChartMetrics(
    params: ChartMetricReq,
    onSuccess: (data: ChartMetricsRes) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(
      this.resource + "/tipsratingsgraph",
      params
    );
    await this.doGet<ChartMetricsRes>(reqParams, onSuccess, onError);
  }
}
