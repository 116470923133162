import { Avatar } from "@mui/material";
import Star from "../icons/Star";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "../../context/toast-context";
import { DashboardService } from "../../services/dashboard-service";
import { TipByEmployee } from "../../services/dto/response/dashboard-response-dto";
import { EmployeesMetricsSkeleton } from "../skeletons/employees-metrics-skeleton";
import { useTranslation } from "react-i18next";

interface EmployeesMetricsProps {
  startDate: string;
  endDate: string;
}

export function EmployeesMetrics({
  endDate,
  startDate,
}: EmployeesMetricsProps) {
  const [data, setData] = useState<TipByEmployee[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { showToast } = useToast();
  const { t } = useTranslation();
  const dashboardService = useMemo(() => new DashboardService(), []);

  const getColorByRating = (rating: number) => {
    // Color del background, mientras más alto el rating más oscuro el color
    if (rating >= 4.5) return "bg-[#2d2f32] text-[#edeff2]";
    if (rating >= 4.0) return "bg-[#5b5f65] text-[#edeff2]";
    if (rating >= 3.5) return "bg-[#868c95] text-[#edeff2]";
    if (rating >= 3.0) return "bg-[#b3bbc7] text-[#edeff2]";
    return "bg-[#c2c8d2] text-[#edeff2]";
  };

  const formatTitle = (
    userName: string,
    groupName: string,
    branchName: string
  ): string => {
    let titleParts = [userName];
    if (groupName) {
      titleParts.push(groupName);
    }
    titleParts.push(branchName);
    return titleParts.join(" | ");
  };

  useEffect(() => {
    const fetchEmployeesMetrics = async () => {
      if (!startDate || !endDate) return;
      setIsLoading(true);
      await dashboardService.getEmployeesMetrics(
        {
          fechadesde: startDate,
          fechahasta: endDate,
        },
        (data) => {
          setData(data.tipsAndRatingsByEmployee);
          setIsLoading(false);
        },
        (error) => {
          showToast(error, "error");
          setIsLoading(false);
        }
      );
    };
    fetchEmployeesMetrics();
  }, [dashboardService, showToast, startDate, endDate]);

  if (isLoading) {
    return <EmployeesMetricsSkeleton />;
  }

  return (
    <ul className="p-4">
      <h2 className="font-extralight tracking-widest mt-4">
        {t("employees-metrics.title")}
      </h2>
      <div className="shrink-0 w-full mt-2 mb-4 bg-white h-[1px]" />
      {data.map((item) => (
        <li
          key={item.userId}
          className={`${getColorByRating(
            Number(item.promedio_rating)
          )} mb-4 p-2 h-32 rounded-2xl flex items-center gap-2 relative`}
        >
          <div className="min-h-10 h-10 min-w-10 w-10 2xl:min-h-12 2xl:h-12 2xl:min-w-12 2xl:w-12 relative">
            <Avatar
              sx={{
                width: "100%",
                height: "100%",
              }}
              src={
                item.imageUrl ??
                "https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="
              }
              alt="Avatar Usuario"
            />
            {Number(item.unRead) > 0 && (
              <span className="absolute -top-1 -left-1 bg-red text-white text-[11px] rounded-full w-4 h-4 flex items-center justify-center">
                {Number(item.unRead) > 99 ? "99" : item.unRead}
              </span>
            )}
          </div>
          <div className="flex-1 min-w-0">
            <div className="flex flex-col gap-1 justify-start max-w-xs truncate">
              <h3
                title={formatTitle(
                  item.userName,
                  item.groupName,
                  item.branchName
                )}
                className="font-normal tracking-wide truncate mb-1 text-sm"
              >
                {formatTitle(item.userName, item.groupName, item.branchName)}
              </h3>
              <div className="flex text-xs font-semibold items-center">
                <div
                  title={item.valoraciones}
                  className="w-20 min-w-20 truncate flex items-center justify-center mr-1 text-center border rounded-full px-1 py-0.5"
                >
                  <span className="flex items-center justify-center">
                    <Star
                      sx={{
                        width: "0.8rem",
                        height: "0.8rem",
                        marginRight: "2px",
                      }}
                    />
                  </span>
                  <span className="truncate">{item.valoraciones}</span>
                </div>
                <span
                  title="Cantidad de valoraciones"
                  className="tracking-wide truncate"
                >
                  {t("employees-metrics.ratings")}
                </span>
              </div>
              <div className="pr-14 flex flex-col gap-1">
                <div className="flex text-xs font-semibold items-center">
                  <div
                    title={item.propinas_promedio}
                    className="w-20 min-w-20 truncate mr-1 text-center border rounded-full px-1 py-0.5"
                  >
                    $ {Number(item.propinas_promedio).toFixed(0)}
                  </div>
                  <span
                    title="Propinas promedio"
                    className="tracking-wide truncate"
                  >
                    {t("employees-metrics.tips")}
                  </span>
                </div>
                <div className="flex text-xs font-semibold items-center">
                  <div
                    title={item.propinas_total}
                    className="w-20 min-w-20 truncate mr-1 text-center border rounded-full px-1 py-0.5"
                  >
                    $ {item.propinas_total}
                  </div>
                  <span
                    title="Propinas totales"
                    className="tracking-wide truncate"
                  >
                    {t("employees-metrics.tips-total")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-5 right-3 flex items-center justify-center">
            <svg
              className="w-12 h-12"
              fill="none"
              stroke="white"
              strokeWidth="1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 .587l3.668 7.57 8.332 1.151-6 5.809 1.415 8.283L12 18.896 4.585 23.4l1.415-8.283-6-5.809 8.332-1.151z"
              />
              <text
                x="50%"
                y="62%"
                fill="white"
                textAnchor="middle"
                fontSize="5.5"
                strokeWidth="0.3"
              >
                {Number(item.promedio_rating).toFixed(1)}
              </text>
            </svg>
          </div>
        </li>
      ))}
    </ul>
  );
}
