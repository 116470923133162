import { useTranslation } from "react-i18next";

export function Legend() {
  const { t } = useTranslation();
  return (
    <div className="flex gap-4 ml-10">
      <div className="flex items-center gap-1">
        <span className="uppercase font-semibold text-[#F3C433] text-sm">
          {t("chart.ratings")}
        </span>
      </div>
      <div className="flex items-center gap-1">
        <span className="uppercase font-semibold text-primary text-sm">
          {t("chart.tips")}
        </span>
      </div>
    </div>
  );
}
