import { DotProps } from "recharts";

// Evitamos mostrar el dot cuando los valores son 0
export function CustomDot(props: DotProps & { value: number | string }) {
  const { cx, cy, value, fill, stroke } = props;
  if (value === 0 || value === "0.00") return null;

  return (
    <circle cx={cx} cy={cy} r={3} fill={fill} stroke={stroke} strokeWidth={2} />
  );
}
