import { useMemo, useState } from "react";
import { PDFService } from "../services/pdf-service";
import { useToast } from "../context/toast-context";
import { Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface DownloadPDFProps {
  userId: number;
  employeeName: string;
  variant?: "default" | "mui";
}

export function DownloadPDF({
  userId,
  employeeName,
  variant = "default",
}: DownloadPDFProps) {
  const [loading, setLoading] = useState(false);

  const pdfService = useMemo(() => new PDFService(), []);
  const { showToast } = useToast();
  const { t } = useTranslation();

  const handleDownloadPDF = async () => {
    setLoading(true);
    await pdfService.getPDF(
      { userId },
      (data) => {
        const buffer = new Uint8Array(data.pdf.data);

        const blob = new Blob([buffer], { type: "application/pdf" });

        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;

        const filename = employeeName.split(" ").join("_");
        link.download = `${filename}.pdf`;
        document.body.appendChild(link);
        link.click();

        // Liberamos la memoria del objectURL y limpiamos el DOM
        link.remove();
        URL.revokeObjectURL(blobUrl);
      },
      (error) => showToast(error, "error")
    );
    setLoading(false);
  };

  if (variant === "default") {
    return (
      <button
        disabled={loading}
        onClick={handleDownloadPDF}
        className="px-24 hover:bg-black/90 mx-auto py-1 flex items-center text-white bg-black rounded-xl disabled:opacity-50"
      >
        {loading ? (
          <CircularProgress
            sx={{
              color: "#fff",
            }}
            size="1.5rem"
            className="animate-spin "
          />
        ) : (
          t("account-verified.employee.download")
        )}
      </button>
    );
  }
  return (
    <Button
      onClick={handleDownloadPDF}
      disabled={loading}
      sx={{
        padding: "2px 8px",
        width: "100%",
        background: "#EF0BB8",
        borderRadius: "16px",
        color: "white",
        textTransform: "uppercase",
        fontFamily: "Montserrat",
        fontWeight: 300,
      }}
    >
      {loading ? (
        <CircularProgress
          sx={{
            color: "#fff",
          }}
          size="1.5rem"
          className="animate-spin "
        />
      ) : (
        t("qr.download")
      )}
    </Button>
  );
}
