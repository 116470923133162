import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Logo from "../assets/img/logos/divixNegro.png";

export function TermsAndConditions() {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between items-center">
        <Typography
          sx={{
            fontFamily: "Montserrat",
            color: "#383838",
          }}
          id="modal-modal-title"
          variant="h4"
          component="h2"
        >
          {t("legal.title")}
        </Typography>
        <img src={Logo} alt="Divix Logo" />
      </div>

      <div className="space-y-8 text-sm text-[#383838] text-pretty hyphens-auto mt-16">
        {/* I. Alcance de estos términos y condiciones */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">I.</span> ALCANCE DE ESTOS TÉRMINOS Y
            CONDICIONES
          </h3>
          <p>
            Estos son los Términos y Condiciones de uso (de aquí en adelante
            denominados “Términos y Condiciones” o “TyC”) de la plataforma DIVIX
            (en adelante denominada “DIVIX”) que te permite medir la calidad de
            tu servicio y digitalizar las propinas.
          </p>
          <p>
            El responsable del sitio web{" "}
            <a
              href="https://www.divix.net"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline"
            >
              www.divix.net
            </a>{" "}
            de la app es DIVIX S.R.L., con domicilio en Mendoza 5735, piso 5
            depto 504, de la Ciudad Autónoma de Buenos Aires, CUIT N°
            33-71859164-9.
          </p>
          <p>
            A toda persona humana o jurídica que acceda, utilice y navegue por
            el sitio web de DIVIX y/o utilice el servicio (de aquí en adelante
            denominado “Usuario” o “Usuarios”), se le solicitará el
            consentimiento con los presentes Términos y Condiciones, luego de lo
            cual, se encontrará obligado a cumplirlos y respetarlos.
          </p>
          <p className="font-semibold">
            CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, LOS
            CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ
            ABSTENERSE DE UTILIZAR EL SERVICIO DIVIX.
          </p>
          <p>
            El objeto de los Términos y Condiciones es brindar un marco
            normativo de referencia respecto del cual medir cualquier actividad
            desarrollada en nuestro sitio por parte de los Usuarios.
          </p>
        </section>
        {/* II. Definiciones */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">II.</span> DEFINICIONES
          </h3>
          <p>
            “Comercio Adherido”: Se refiere a todo comercio que brinde servicios
            cuyos colaboradores en forma habitual u ocasional reciben propinas
            que se registre en el Sitio Web DIVIX para brindar el servicio.
          </p>

          <p>
            “Consumidores Finales”: Se refiere a los clientes de los Comercios
            Adheridos que adquieren sus productos y servicios con destino final.
          </p>

          <p>
            “Datos personales”: Es toda información de cualquier tipo referida a
            personas humanas o de existencia ideal determinadas o determinables.
          </p>

          <p>
            “Propina”: Gratificación por la atención recibida por parte del
            Usuario que será pagada por el Consumidor Final y que podrá ser
            cobrada en formato digital a través del Servicio.
          </p>

          <p>
            “Usuario”: Es todo aquel colaborador que posee una relación con el
            Comercio Adherido donde brinda sus servicios, ya sea de modo
            ocasional o de forma regular ya sea bajo un formato de locación de
            servicios o de relación en el marco de un contrato de trabajo.
          </p>

          <p>
            “Servicios”: Se refiere a los servicios que brinda DIVIX que tienen
            como finalidad calificar a los Usuarios y cobrar propinas de modo
            digital a través de tarjeta de crédito, débito o prepaga a los
            consumidores finales.
          </p>

          <p>
            “Sitio Web DIVIX”: Se refiere a una plataforma digital de acceso
            público cuyo dominio es{" "}
            <a
              href="https://www.divix.net"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline"
            >
              www.divix.net
            </a>{" "}
            para la interacción y la intermediación entre Usuarios y Comercio
            Adheridos por un lado, y para el conocimiento de los Servicios que
            brinda Divix por parte de los Consumidores Finales o visitantes.
          </p>

          <p>
            Los términos definidos anteriormente incluirán el plural y
            viceversa.
          </p>
        </section>
        {/* III. Objeto */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">III.</span> OBJETO
          </h3>
          <p>
            El Sitio Web DIVIX provee una herramienta tecnológica para los
            Comercios Adheridos que permite que sus Consumidores Finales puedan
            calificar la atención de los Usuarios y pagar propinas en formato
            digital a través de la utilización de los Servicios.
          </p>
          <p>
            Por otro lado, al adherirse los Comercios Adheridos, pueden optar
            por un modelo a medida determinado para la distribución de las
            propinas dentro de sus equipos de trabajo, así como la posibilidad
            de ofrecer los Servicios como marca blanca.
          </p>
          <p>
            Por su parte, a los Usuarios que se registren, les brinda la
            posibilidad de cobrar Propinas en formato digital a través de un QR
            personal asignado por DIVIX.
          </p>
          <p>
            Finalmente, en el caso de los Consumidores Finales, el Servicio les
            permite calificar la atención recibida y/o realizar el pago de
            Propinas en formato digital tomando el QR personal de la persona a
            la que deseen enviarle una Propina o calificación.
          </p>
          <p>
            DIVIX actúa exclusivamente como prestador de una solución
            tecnológica que permite la interacción y encuentro entre las partes
            involucradas, no siendo responsable ni prestando ninguna garantía
            respecto de la atención de los Comercios Adheridos ni de las
            calificaciones o propinas que eventualmente se destinarán a los
            Usuarios.
          </p>
        </section>
        {/* IV. Capacidad */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">IV.</span> CAPACIDAD
          </h3>
          <p>
            Solo podrán utilizar el Sitio Web DIVIX las personas mayores de 18
            años de edad y que posean capacidad legal para contratar.
          </p>
          <p>
            Los Comercios Adheridos que se registren como personas jurídicas
            deberán hacerlo a través de sus representantes legales o apoderados,
            con capacidad necesaria para representarlos legalmente, en el caso
            de personas humanas, con la documentación que acredite su
            identificación. A tal fin, DIVIX podrá requerir documentación
            adicional a efectos de verificar la documentación necesaria.
          </p>
        </section>
        {/* V. Registro */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">V.</span> REGISTRO
          </h3>
          <p>
            Para poder acceder a los Servicios, el proceso se inicia con una
            primera entrevista de DIVIX con quien se quiere registrar y la
            explicación de los Servicios, luego los Comercios Adheridos deberán
            completar el formulario de registración obligatorio con la
            información mínima necesaria requerida por DIVIX
          </p>
          <p>
            Los campos requeridos como datos de registro son: una contraseña, el
            nombre real o razón social completo, el N° de DNI /ID (junto con un
            scan del frente y dorso del mismo), el número identificatorio de la
            empresa, una casilla de correo electrónico válida, un teléfono
            válido, la condición impositiva, el país, la provincia, la calle con
            número, código postal. A su vez, los Comercios Adheridos deberán
            indicar el modelo de distribución que incluya los porcentajes según
            los integrantes de cada equipo de trabajo, así como la cantidad de
            colaboradores o prestadores de servicios y sus datos de contacto
            para que DIVIX pueda contactarlos a efectos de registración y envío
            de QR.
          </p>
          <p>
            Los Usuarios que deseen utilizar el Servicio también deberán
            registrarse con los siguientes campos requeridos: una contraseña, el
            nombre real completo, el N° de DNI (junto con un scan del frente y
            dorso del mismo), el CUIT, una casilla de correo electrónico válida,
            un teléfono válido, número de CBU o alias donde desean recibir las
            acreditaciones exclusivamente por las Propinas recibidas, no
            pudiendo recibir otros importes que no estén asociados a este
            concepto.
          </p>
          <p>
            La información de registro deberá ser exacta, precisa y verdadera.
            Los Comercios Adheridos y los Usuarios asumen el compromiso de
            mantener actualizados sus datos.
          </p>
        </section>
        {/* VI. CUENTA DEL USUARIO Y DEL COMERCIO ADHERIDO */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">VI.</span> CUENTA DEL USUARIO Y DEL COMERCIO
            ADHERIDO
          </h3>
          <p>
            El Comercio Adherido accederá a su cuenta a través del Sitio Web
            DIVIX, mediante el ingreso de su email y clave de seguridad personal
            elegida ("Clave de Seguridad"). Ello, con la finalidad de actualizar
            la información de los Usuarios, dar de alta nuevos Usuarios para que
            sean contactados por DIVIX o dando de baja a aquellos que ya no
            formen parte de sus organizaciones.
          </p>
          <p>
            DIVIX facturará de modo mensual a los Usuarios por el cobro del
            servicio que será informado al momento de completar el formulario de
            registración. El costo del servicio tiene como finalidad el
            mantenimiento de la plataforma así como la difusión de la misma en
            los Consumidores Finales.
          </p>
          <p>
            El Usuario podrá visualizar en el Sitio Web de Divix el reporte de
            las calificaciones recibidas y los importes generados en concepto de
            Propinas. Asimismo, podrá visualizar pero no modificar el formato de
            distribución dentro del Comercio Adherido del resultado de las
            Propinas recibidas entre todos sus colaboradores.
          </p>
          <p>
            La venta, cesión o transferencia de la cuenta del Usuario o del
            Comercio Adherido (incluyendo la reputación lograda), sólo podrá
            realizarse previa conformidad expresa de DIVIX. El Usuario es el
            único responsable por todas las operaciones efectuadas en su cuenta.
            El Usuario se compromete a notificar a DIVIX en forma inmediata,
            cualquier uso no autorizado de su cuenta, así como el ingreso por
            terceros no autorizados a la misma.
          </p>
          <p>
            En determinados casos DIVIX podrá rechazar solicitudes de
            registración o cancelar una registración previamente aceptada, sin
            que ello genere derecho a indemnización o resarcimiento, siempre y
            cuando funde debidamente su decisión en criterios objetivos y se lo
            comunique al Usuario o al Comercio Adherido.
          </p>
        </section>
        {/* VII.  VERIFICACIÓN DE LA CUENTA DEL USUARIO */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">VII.</span> VERIFICACIÓN DE LA CUENTA DEL
            USUARIO
          </h3>
          <p>
            El N° de DNI presentado por los Usuarios será validado de manera
            interna. El fin de aquella verificación es poder corroborar la
            identidad del Usuario.
          </p>
          <p>
            Mientras el proceso se encuentre en vigencia, el Usuario aparecerá
            como no validado, hasta tanto la cuenta se encuentre verificada, en
            cuyo caso aparecerá el logo distintivo de validación.
          </p>
          <p>
            Una vez finalizado el proceso se dejará constancia de que la
            identidad del Usuario se encuentra validada.
          </p>
        </section>
        {/* VIII.  MODIFICACIONES A LOS TyC */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">VIII.</span> MODIFICACIONES A LOS TyC
          </h3>
          <p>
            DIVIX se reserva la posibilidad de modificar los presentes TyC en
            cualquier momento, siempre haciendo públicos los cambios en el Sitio
            Web DIVIX e informando mediante correo electrónico a los Comercios
            Adheridos y los Usuarios que se han realizado modificaciones junto
            al link a la versión actualizada de los mismos.
          </p>
          <p>
            Todo Usuario o Comercio Adherido que no esté de acuerdo con las
            modificaciones efectuadas podrá solicitar la baja de su cuenta sin
            costo alguno. La baja podrán solicitarla automáticamente en el
            apartado de “Configuraciones” y/o a través del siguiente correo
            electrónico: bajas@divix.net. En el caso que se procese la baja del
            Comercio Adherido, ello generará como consecuencia la baja
            automática de todos los Usuarios así como las sucursales del
            Comercio Adherido asociados de modo automático.
          </p>
          <p>
            Los importes en concepto de Propinas devengados serán transferidos
            dentro de los plazos estipulados aún cuando haya un proceso de baja
            de la cuenta.
          </p>
        </section>
        {/* IX.  PROCEDIMIENTO DE USO DEL SITIO WEB */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">IX.</span> PROCEDIMIENTO DE USO DEL SITIO WEB
          </h3>
          <h4 className="text-lg font-semibold text-[#EF0BB8]">
            <span className="mr-4">a.</span> Servicios ofrecidos a los Comercios
            Adheridos
          </h4>
          <p>
            Los Comercios Adheridos para utilizar el Servicio deberán
            registrarse completando la información y agregando la documentación
            solicitada. Asimismo, deberán referir los nombres de los
            colaboradores o prestadores dentro de su Comercio Adherido para que
            DIVIX les informe del alta como Comercio Adherido y los invite a
            adherirse como Usuarios, para lo cual deberán completar el
            correspondiente formulario de registración con sus datos. La
            registración como Comercio Adherido será gratuita siempre que sea
            ofrecida en modo exclusivo, lo que significa que el Comercio
            Adherido no podrá ofrecer un servicio similar al de DIVIX mediante
            el cual se ofrezca un QR para el cobro de Propinas. El Comercio
            Adherido también podrá optar por un plan de marca blanca en cuyo
            caso DIVIX le formulará una propuesta comercial particular en
            función de sus necesidades.
          </p>
          <h4 className="text-lg font-semibold text-[#EF0BB8]">
            <span className="mr-4">b.</span> Servicios ofrecidos al Usuario
          </h4>
          <p>
            Los Usuarios luego de completar correctamente el formulario de
            registración, podrán empezar a recibir en su cuenta personal los
            importes que le correspondan de acuerdo al los porcentajes de
            distribución entre el equipo de trabajo informado por el Comercio
            Adherido que le asignará DIVIX. Esto último, según la distribución
            informada por el Comercio Adherido al momento del registro. El pago
            de las Propinas se realizará con el correspondiente descuento por el
            costo del servicio que es informado al momento de la registración.
          </p>
          <h4 className="text-lg font-semibold text-[#EF0BB8]">
            <span className="mr-4">c.</span> Servicios ofrecidos a los
            Consumidores Finales
          </h4>
          <p>
            Los Consumidores Finales no tendrán que completar ningún formulario
            de registración pero podrán realizar calificaciones y dejar mensajes
            directos a los responsables de cada Comercio Adherido o a quienes
            los hayan atendido en los Comercios Adheridos y pagarles Propinas
            escaneando sus QR.
          </p>
        </section>
        {/* X. POLÍTICAS DE PRIVACIDAD */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">X.</span> POLÍTICAS DE PRIVACIDAD
          </h3>
          <p>
            Para acceder, navegar y utilizar el Sitio Web DIVIX, los Comercios
            Adheridos y los Usuarios deberán facilitar sus datos de carácter
            personal establecidos en los presentes TyC. La información personal
            se procesa y almacena en servidores o medios magnéticos que
            mantienen altos estándares de seguridad y protección tanto física
            como lógica.
          </p>
          <p>
            Para mayor información sobre la privacidad de los Datos Personales y
            casos en los que será revelada la información personal, se puede
            consultar nuestra <strong>Política de Privacidad</strong> que
            también debe ser aceptada para poder utilizar el Sitio Web DIVIX.
          </p>
        </section>
        {/* XI. OBLIGACIONES DE LOS COMERCIOS ADHERIDOS */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">XI.</span> OBLIGACIONES DE LOS COMERCIOS
            ADHERIDOS
          </h3>
          <p>
            El Comercio Adherido es el responsable por todas las obligaciones y
            cargas impositivas que correspondan por la puesta a disposición de
            sus Servicios, sin que pudiera imputársele a DIVIX algún tipo de
            responsabilidad por incumplimientos de ese estilo. DIVIX no es
            responsable por el efectivo cumplimiento de las obligaciones
            fiscales o impositivas establecidas por la ley vigente.
          </p>
        </section>
        {/* XII. ACCIONES CONTRARIAS AL USO DE LA PLATAFORMA */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">XII.</span> ACCIONES CONTRARIAS AL USO DE LA
            PLATAFORMA ADHERIDOS
          </h3>
          <p>
            No está permitida ninguna acción o uso de dispositivo, software, u
            otro medio tendiente a interferir tanto en las actividades y
            operatoria de DIVIX o del Sitio Web DIVIX.
          </p>
          <p>
            Cualquier intromisión, tentativa o actividad violatoria o contraria
            a las leyes sobre derechos de propiedad intelectual e Industrial y/o
            a las prohibiciones estipuladas en estos TyC harán pasible a su
            responsable de las acciones legales pertinentes, y de las sanciones
            previstas por este documento, así como lo hará responsable de
            indemnizar los daños ocasionados.
          </p>
        </section>
        {/* XIII. INCUMPLIMIENTO A LOS TyC */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">XIII.</span> INCUMPLIMIENTO A LOS TyC
          </h3>
          <p>
            DIVIX podrá advertir, suspender en forma temporal o definitiva la
            cuenta de un Comercio Adherido, de un Usuario o bloquear algún
            comentario de un Consumidor Final que si: (a) se incumpliera alguna
            ley, o cualquiera de las estipulaciones de los presentes TyC y demás
            documentos del Sitio Web DIVIX; (b) si fuera ofensivo, agraviante o
            sea contrario a la moral y buenas costumbres.
          </p>
        </section>
        {/* XIV. RESPONSABILIDADES */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">XIV.</span> RESPONSABILIDADES
          </h3>
          <p>
            DIVIX sólo facilita un espacio virtual que le permite a los
            Comercios Adheridos y los Usuarios contactarse a través del Sitio
            Web DIVIX con los Consumidores Finales para recibir calificaciones y
            Propinas en formato digital.
          </p>
          <p>
            DIVIX no asegura montos de Propinas determinados ni de
            calificaciones. Es por ello que no será responsable respecto de la
            existencia, calidad, cantidad de calificaciones recibidas ni de
            Propinas.
          </p>
          <p>
            Cada Usuario conoce y acepta ser el exclusivo responsable de exhibir
            el QR que le fue asignado para que a través del mismo el Consumidor
            Final pueda calificarlo y pagarle una Propina.
          </p>
          <p>
            DIVIX no tiene ninguna participación en la definición del Comercio
            Adherido en el modelo de distribución de los importes generados como
            Propina, el cual será informado a los Usuarios. Cualquier duda o
            consulta relacionada con el modelo de distribución elegido deberá
            ser canalizada entre los Usuarios y los Comercios Adheridos.
          </p>
        </section>
        {/* XV. INDEMNIDAD */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">XV.</span> INDEMNIDAD
          </h3>
          <p>
            La aceptación de estos TyC por parte de los Comercios Adheridos y
            los Usuarios no crea ningún contrato de sociedad, de mandato, de
            franquicia, o relación laboral entre DIVIX y los Usuarios o los
            Comercios Adheridos. El Usuario reconoce y acepta que DIVIX no es
            parte en la definición de la distribución de las Propinas que se
            encuentra a cargo del Comercio Adherido.
          </p>
          <p>
            El Comercio Adherido y los Usuarios mantendrán indemne a DIVIX, así
            como a sus directivos, sucesores, administradores, accionistas,
            representantes y empleados, frente a cualquier reclamo iniciado por
            Usuarios o terceros, así como también frente a cualquier sanción
            impuesta por las autoridades gubernamentales, con fundamento o causa
            en el vínculo laboral o de prestación de servicios entre los
            Comercios Adheridos y los Usuarios.
          </p>
          <p>
            A tal fin, el Comercio Adherido en caso de recibir DIVIX una
            demanda, reclamo o iniciarse un procedimiento administrativo en su
            contra por parte de un Usuario, faculta a DIVIX a retener y debitar
            de su cuenta cualquier importe que corresponda al Comercio Adherido
            para recuperar los gastos incurridos en dichos procesos, los cuales
            podrán incluir pero sin limitar, los gastos, multas, sentencias,
            daños y los honorarios de los letrados intervinientes y las costas
            judiciales en una cantidad razonable, y/o generar débitos
            específicos en su facturación.
          </p>
          <p>
            En caso de que uno o más Usuarios inicien cualquier tipo de reclamo
            o acciones legales contra un Comercio Adherido, todos y cada uno de
            los Usuarios involucrados en dichos reclamos o acciones eximen de
            toda responsabilidad a DIVIX, a sus directores, accionistas y
            empleados.
          </p>
          <p>
            DIVIX no se responsabiliza por cualquier daño, perjuicio o pérdida
            al Usuario causados por fallas o interrupciones en el servidor o en
            la red Internet. DIVIX no es responsable por cualquier software
            malicioso que pudiera infectar el equipo del Usuario como
            consecuencia del acceso, uso o examen del Sitio Web DIVIX.
          </p>
          <p>
            Los Usuarios no podrán imputarle responsabilidad alguna ni exigir
            pago por lucro cesante, ni de cualquier otro daño, en virtud de
            perjuicios resultantes de dificultades técnicas o fallas en el Sitio
            Web DIVIX o en la Red Internet.
          </p>
          <p>
            DIVIX no garantiza el acceso y uso continuado o ininterrumpido del
            Sitio Web DIVIX. El sistema puede eventualmente no estar disponible
            debido a dificultades técnicas o fallas de la red Internet, o por
            cualquier otra circunstancia ajena.
          </p>
          <p>
            En los casos de que el Sitio Web DIVIX se encuentre fuera de
            servicio, se procurará el restablecimiento con la mayor celeridad
            posible sin que por ello pueda imputársele algún tipo de
            responsabilidad a DIVIX.
          </p>
        </section>
        {/* XVI. JURISDICCIÓN Y LEY APLICABLE */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">XVI.</span> JURISDICCIÓN Y LEY APLICABLE
          </h3>
          <p>
            Estos TyC así como su interpretación y cumplimiento, estará regido
            en todos sus puntos por las leyes vigentes en la República
            Argentina.
          </p>
          <p>
            Cualquier controversia derivada de los presentes TyC, será sometida
            ante la Justicia Nacional Ordinaria competente de acuerdo a la
            legislación vigente.
          </p>
          <p>
            Última versión: <strong>23/10/2024</strong>
          </p>
        </section>
      </div>

      {/* POLITICAS DE PRIVACIDAD */}
      <div className="space-y-8 text-sm text-[#383838] text-pretty hyphens-auto mt-16">
        <h1 className="text-center text-[#EF0BB8] text-xl font-semibold">
          Políticas de Privacidad
        </h1>
        <section className="space-y-4 ">
          <p>
            Creamos las presentes Políticas de Privacidad (“PDP”) con el
            importante propósito de que todos los visitantes del Sitio Web DIVIX
            de DIVIX S.R.L. (“DIVIX”), conozcan las prácticas relacionadas al
            tratamiento de la información que desarrolla el Sitio Web DIVIX de
            la forma más precisa posible.
          </p>
          <p>
            El responsable del tratamiento de Datos Personales en el Sitio Web
            DIVIX es DIVIX S.R.L., con domicilio en Mendoza 5735, piso 5 depto
            504, de la Ciudad Autónoma de Buenos Aires, CUIT N° 33-71859164-9.
          </p>
          <p>
            Todos los Usuarios, al registrarse y/o navegar en el Sitio Web
            DIVIX, prestan su consentimiento para que se utilicen los Datos
            Personales de acuerdo a los TyC y a las PDP que se describen a
            continuación.
          </p>
        </section>
        {/* I. Objeto */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">I.</span> Objeto
          </h3>
          <p>
            Estas PDP describen los Datos Personales que DIVIX recoge sobre sus
            Comercios Adheridos y Usuarios o visitantes del Sitio Web DIVIX lo
            que puede hacerse con dicha información.
          </p>
          <p>
            La privacidad de la información de los Usuarios es un recurso
            importante para DIVIX. Es por esa razón que se toman las
            precauciones y recaudos para resguardar la información que se
            obtiene, utilizando los mecanismos de seguridad informática de
            protección de la información más completos y eficaces.
          </p>
          <p>
            Este documento es parte integrante de los TyC de DIVIX. Mediante la
            aceptación de los TyC en el momento de la navegación y/o
            registración del Usuario en el Sitio Web DIVIX, se acepta las PDP
            aquí contenidas.
          </p>
          <p>
            Estas PDP y algunas de las cláusulas aquí contenidas deben ser
            interpretadas en conjunto con la Ley de Protección de Datos
            Personales de la Argentina (Ley N°25.326).
          </p>
        </section>
        {/* II. Derechos de los titulares de Datos Personales */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">II.</span> Derechos de los titulares de Datos
            Personales
          </h3>
          <p>
            Los Usuarios, los Comercios Adheridos, los Consumidores Finales y
            los visitantes del Sitio Web DIVIX tienen reconocidos y podrán
            ejercitar los derechos de acceder, rectificar, suprimir y actualizar
            sus Datos Personales, así como a oponerse al tratamiento de los
            mismos como también a ser informado de las cesiones llevadas a cabo
            por DIVIX. Todo ello de conformidad a lo dispuesto en la normativa
            aplicable en la Argentina.
          </p>
          <p>
            Conforme se establece en la Ley Nº 25.326, el titular de los Datos
            Personales, previa acreditación de su identidad tiene la facultad de
            ejercer el derecho de acceso a los mismos, dentro de los 10 (diez)
            días corridos desde la acreditación de su identidad, en forma
            gratuita a intervalos no inferiores a seis meses, salvo que se
            acredite un interés legítimo para tal efecto. Para la rectificación
            y supresión de los Datos Personales, el titular de los mismos podrá
            ejercer tales derechos dentro de los 5 (cinco) días hábiles de
            acreditada su identidad.
          </p>
          <p>
            DIVIX podrá, en cualquier momento, modificar los términos
            establecidos en las PDP y/o las prácticas de envío de Correo
            electrónico a sus Usuarios registrados en el Sitio Web DIVIX. Ante
            cualquier cambio material a nuestras PDP, se notificará en el Sitio
            Web DIVIX publicando una versión actualizada en esta sección y/o
            mediante el envío de un correo electrónico a sus cuentas
            registradas.
          </p>
          <p>
            En caso de que cualquier Usuario o Comercio Adherido registrado en
            el Sitio Web DIVIX quisiera ejercer alguno de los derechos
            reconocidos en la presente cláusula deberá hacerlo por correo al
            domicilio de DIVIX.
          </p>
          <p>
            Mediante las presentes PDP se informa que la Agencia de Acceso a la
            Información Pública, es el Órgano de Control de la Ley N° 25.326 y
            tiene la atribución de atender las denuncias y/o reclamos que se
            interpongan con relación al incumplimiento de las normas sobre
            protección de Datos Personales.
          </p>
        </section>
        {/* III. Qué información recabamos. Consentimiento */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">III.</span> Qué información recabamos.
            Consentimiento
          </h3>
          <p>
            Lo primero que se debe hacer para utilizar los servicios del Sitio
            Web DIVIX, en el caso de Comercios Adheridos y Usuarios, es
            registrarse, suministrando ciertos Datos Personales completos y
            exactos.
          </p>
          <p>
            DIVIX podrá validar los Datos Personales suministrados acudiendo a
            entidades públicas o compañías especializadas en riesgo y datos,
            para lo cual el titular autoriza expresamente a la validación de sus
            Datos Personales. La información que DIVIX obtenga de estas
            entidades será tratada en forma confidencial.
          </p>
          <p>
            El Usuario o Comercio Adherido que se registra en el Sitio Web
            DIVIX, consiente expresamente que DIVIX tenga acceso, en cualquier
            momento, a la totalidad de Datos Personales de su Cuenta, incluyendo
            en particular, pero sin limitación, a datos sobre sus intereses,
            gustos, contactos y cualquier otro contenido alojado en su cuenta.
          </p>
          <p>
            A su vez, consiente que se incluyan en dicha cuenta comentarios,
            fotografías, videos y cualquier otro tipo de contenido multimedia
            que lo identifique.
          </p>
          <p>
            Por último, otorga su consentimiento a que se envíe al correo
            electrónico del Usuario, vinculado a la cuenta, información o
            mensajes con las finalidades descritas en las presentes PDP.
          </p>
          <p>
            DIVIX recoge y almacena automáticamente cierta información sobre la
            actividad de los Usuarios y Comercios Adheridos en el marco del
            Servicio. Tal información puede incluir la URL de la que provienen
            (estén o no en el Sitio Web DIVIX), a qué URL acceden seguidamente
            (estén o no en el Sitio Web DIVIX), qué navegador están usando, y
            sus direcciones IP. También las páginas visitadas, las búsquedas
            realizadas, las publicaciones, compras o ventas, calificaciones y
            réplicas ingresadas, mensajes en los foros, entre otra información
            podrá ser almacenada y retenida.
          </p>
          <p>
            En cuanto a los visitantes del Sitio Web DIVIX, DIVIX no almacenará
            nombres, direcciones postales, datos bancarios, números de cédula de
            identidad y/o documentos de identidad, ni ninguna otra información
            que permita identificar o haga identificable a una persona.
          </p>
        </section>
        {/* IV. Finalidades */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">IV.</span> Finalidades
          </h3>
          <p>
            Para prestar los Servicios disponibles en el Sitio Web DIVIX, DIVIX
            requiere cierta información de carácter personal (Datos Personales).
            La recolección de aquellos Datos Personales permite ofrecer a los
            Usuarios y a los Comercios Adheridos funcionalidades que se adecúen
            mejor a sus necesidades y personalizar los Servicios para hacer que
            sus experiencias en relación con el Sitio Web DIVIX sean cómodas.
          </p>
          <p>
            Los Datos Personales que se recaban tienen las siguientes
            finalidades:
          </p>
        </section>
        {/* V. Confidencialidad de la información */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">V.</span> Confidencialidad de la información
          </h3>
          <p>
            DIVIX se compromete a proteger la privacidad y confidencialidad de
            la información de los Usuarios registrados en el Sitio Web DIVIX.
          </p>
          <p>Contraseña Personal</p>
          <p>
            Los Usuarios y Comercios Adheridos debidamente registrados
            dispondrán de una contraseña personal. Con ella podrán ingresar al
            Sitio Web DIVIX. Esta contraseña deberá mantenerse bajo absoluta
            confidencialidad y, en ningún caso, deberá revelarse o compartirse
            con otras personas.
          </p>
        </section>
        {/* VI. Menores de Edad */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">VI.</span> Menores de Edad
          </h3>
          <p>
            El uso del Sitio Web DIVIX sólo está disponible para aquellas
            personas que tienen capacidad legal para contratar según la
            normativa argentina. Por lo tanto, aquellos que no cumplan con esta
            condición deberán abstenerse de suministrar información personal
            para ser incluida en nuestras bases de datos.
          </p>
        </section>
        {/* VII. Uso de Cookies */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">VII.</span> Uso de Cookies
          </h3>
          <p>
            Los Usuarios y Comercios Adheridos conocen y aceptan que DIVIX podrá
            utilizar un sistema de seguimiento mediante la utilización de
            Cookies.
          </p>
          <p>
            Las Cookies son pequeños archivos que se instalan en el Disco Rígido
            de la computadora del Usuario o del Comercio Adherido, con una
            duración limitada en el tiempo y que ayudan a personalizar los
            servicios a los que cada uno de éstos accede. También se ofrecen
            ciertas funcionalidades que sólo están disponibles mediante el
            empleo de Cookies. Las Cookies se utilizan para identificar el
            dispositivo el cual se relaciona con una cuenta de un Usuario o de
            un Visitante donde se logra identificar su comportamiento y
            acciones. De aquella manera se comprende mejor las necesidades e
            intereses, conforme la información relacionada a su navegación.
          </p>
          <p>
            DIVIX utiliza la información obtenida por intermedio de las Cookies
            para analizar las páginas navegadas por el Usuario; las búsquedas
            realizadas por aquellos; para mejorar la disponibilidad de Servicios
            a través del Sitio Web DIVIX; para mostrar publicidad o promociones,
            banners de interés, noticias sobre DIVIX; y para perfeccionar la
            oferta de Servicios, personalizar dichos Servicios y su
            presentación; también se utilizan Cookies para promover y hacer
            cumplir las reglas y la seguridad del Sitio Web DIVIX.
          </p>
          <p>
            El Sitio Web DIVIX utiliza, adicionalmente, las Cookies para que el
            Usuario no tenga que introducir su contraseña tan frecuentemente
            durante una sesión de navegación, también para contabilizar y
            corroborar las registraciones, la actividad del Usuario; y para
            acuerdos de publicidad, siempre teniendo como objetivo que la
            instalación de las Cookies, es en beneficio del Usuario que reciben
            la información, y que no será usado con otros fines ajenos a las
            presentes PDP.
          </p>
          <p>
            Se establece que la instalación, permanencia y existencia de las
            Cookies en la computadora del Usuario depende de su exclusiva
            voluntad y puede ser eliminada cuando así lo deseen.
          </p>
          <p>
            DIVIX almacena Cookies para poder ofrecer una experiencia más
            interactiva en el Sitio Web DIVIX, basada en las acciones del
            Usuario. El Usuario puede, en cualquier momento, eliminar las
            Cookies almacenadas y configurar su navegador web para que solicite
            aprobación antes de almacenarlas o directamente para evitar que se
            almacenen.
          </p>
          <p>
            Este procedimiento se realiza de forma diferente en los distintos
            navegadores web existentes y es necesario realizarlo en cada
            computadora en la que se ingrese al Sitio Web DIVIX. A continuación,
            ponemos a disposición los diferentes procedimientos para manejar
            esta configuración en los navegadores más utilizados:
          </p>
          <p className="break-words">
            - Microsoft Windows Edge:
            <a
              href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
              target="_blank"
              rel="noreferrer"
              className="ml-2 text-blue-500 underline"
            >
              https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
            </a>
            <br />- Google Chrome:
            <a
              href="https://support.google.com/chrome/answer/95647?hl=es&p=cpn_cookies"
              target="_blank"
              rel="noreferrer"
              className="ml-2 text-blue-500 underline"
            >
              https://support.google.com/chrome/answer/95647?hl=es&p=cpn_cookies
            </a>
            <br />- Mozilla Firefox:
            <a
              href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
              target="_blank"
              rel="noreferrer"
              className="ml-2 text-blue-500 underline"
            >
              https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
            </a>
            <br />- Apple Safari:
            <a
              href="https://support.apple.com/kb/PH21411?viewlocale=es_US&locale=es_US"
              target="_blank"
              rel="noreferrer"
              className="ml-2 text-blue-500 underline"
            >
              https://support.apple.com/kb/PH21411?viewlocale=es_US&locale=es_US
            </a>
          </p>
        </section>
        {/* VIII. Uso de Web Beacons */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">VIII.</span> Uso de Web Beacons
          </h3>
          <p>
            Un Web beacon es una imagen electrónica, también llamada
            single-pixel (1 x 1) o pixel transparente, que es colocada en código
            de un sitio web. Un Web beacon tiene finalidades similares a las
            Cookies.
          </p>
          <p>
            Adicionalmente un Web beacon es utilizado para medir patrones de
            tráfico de los usuarios de una página a otra con el objeto de
            maximizar como fluye el tráfico a través de la Web.
          </p>
          <p>
            El Usuario del Sitio Web DIVIX conoce y acepta que DIVIX podrá
            utilizar un sistema de seguimiento mediante la utilización de Web
            beacons.
          </p>
        </section>
        {/* IX. Spam */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">IX.</span> Spam
          </h3>
          <p>
            DIVIX, y sus Usuarios no aceptan conductas consideradas "spamming",
            ya sea en opiniones, preguntas, respuestas y/o el envío no
            solicitado de correos electrónicos.
          </p>
          <p>
            DIVIX podrá suspender o inhabilitar definitivamente a aquellos
            usuarios que violen los TyC y las presentes PDP.
          </p>
        </section>
        {/* X. Seguridad. Almacenamiento de la información */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">X.</span> Seguridad. Almacenamiento de la
            información
          </h3>
          <p>
            DIVIX utiliza los estándares de la industria en materia de
            protección de la confidencialidad de los Datos Personales de sus
            Usuarios, incluyendo, entre otras medidas, cortafuegos ("firewalls")
            y Secure Socket Layers ("SSL").
          </p>
          <p>
            DIVIX considera a los Datos Personales de sus Usuarios como un
            activo que debe ser protegido de cualquier pérdida o acceso no
            autorizado. Para cumplir tal cometido se emplean diversas técnicas
            de seguridad para proteger tales datos de accesos no autorizados por
            Usuarios que utilicen el Sitio Web DIVIX desde dentro o fuera de
            nuestra compañía. Sin embargo, es necesario tener muy en cuenta que
            la seguridad perfecta no existe en Internet.
          </p>
          <p>
            Los Usuarios del Sitio Web DIVIX conocen y aceptan expresamente que
            DIVIX, a su exclusivo criterio, recabe, almacene y monitoree el
            intercambio de mensajes entre sus Usuarios, efectuados dentro del
            Sitio Web DIVIX, con el objetivo de contribuir a la seguridad de las
            relaciones y comunicaciones en el Sitio Web DIVIX.
          </p>
          <p>
            Todos los Datos Personales de los Usuarios y de los Comercios
            Adheridos serán almacenados en un banco automatizado de Datos
            Personales.
          </p>
        </section>
        {/* XI. Preferencias de Publicidad y Correos Electrónicos */}
        <section className="space-y-4 ">
          <h3 className="text-xl font-semibold text-[#EF0BB8]">
            <span className="mr-4">XI.</span> Preferencias de Publicidad y
            Correos Electrónicos
          </h3>
          <p>
            Sin perjuicio que DIVIX desea mantener a los Usuarios actualizados
            en todo momento sobre promociones, novedades, cambios y otras
            comunicaciones, los Usuarios y los Comercios Adheridos pueden optar
            recibir a sus correos electrónicos registrados en el Sitio Web
            DIVIX, información diferenciada.
          </p>
        </section>
      </div>
    </>
  );
}
