import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ALLOWED_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

interface DNIUploaderProps {
  onFilesChange: (frontFile: File | null, backFile: File | null) => void;
}

export function DNIUploader({ onFilesChange }: DNIUploaderProps) {
  const [frontImage, setFrontImage] = useState<{
    file: File | null;
    error: string;
  }>({ file: null, error: "" });
  const [backImage, setBackImage] = useState<{
    file: File | null;
    error: string;
  }>({ file: null, error: "" });
  const { t } = useTranslation();

  const validateImage = (file: File): { isValid: boolean; error: string } => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      return {
        isValid: false,
        error: t("images.document.type"),
      };
    }

    return { isValid: true, error: "" };
  };

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "front" | "back"
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const { error, isValid } = validateImage(file);
    if (!isValid) {
      type === "front"
        ? setFrontImage((prev) => ({ ...prev, error: error }))
        : setBackImage((prev) => ({ ...prev, error: error }));
      return;
    }

    if (type === "front") {
      setFrontImage({ file, error: "" });
      onFilesChange(file, backImage.file);
    } else {
      setBackImage({ file, error: "" });
      onFilesChange(frontImage.file, file);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label
          htmlFor="frontImage"
          className="block text-sm font-medium mb-2 border bg-primary text-white rounded-full cursor-pointer"
        >
          {t("employee.verify.document.front")}
        </label>
        <input
          type="file"
          id="frontImage"
          name="frontImage"
          accept="image/*"
          className="hidden"
          onChange={(e) => handleFileChange(e, "front")}
        />
        <div className="text-xs break-words w-full">
          {frontImage.file
            ? frontImage.file.name
            : t("images.documents.no-file")}
        </div>
        {frontImage.error && (
          <p className="text-red text-xs mt-1">
            {t("employee.verify.document.errors.front")}
          </p>
        )}
      </div>

      <div>
        <label
          htmlFor="backImage"
          className="block text-sm font-medium mb-2 border bg-primary text-white rounded-full cursor-pointer"
        >
          {t("employee.verify.document.back")}
        </label>
        <input
          type="file"
          id="backImage"
          name="backImage"
          accept="image/*"
          className="hidden"
          onChange={(e) => handleFileChange(e, "back")}
        />
        <div className="text-xs break-words w-full">
          {backImage.file ? backImage.file.name : t("images.documents.no-file")}
        </div>
        {backImage.error && (
          <p className="text-red text-xs mt-1">
            {t("employee.verify.document.errors.back")}
          </p>
        )}
      </div>
    </div>
  );
}
