import { useEffect, useMemo, useState } from "react";
import Logo from "../assets/img/logos/X.svg";
import { UnverifiedUser } from "../services/dto/response/user-response-dto";
import { Link, useLocation } from "react-router-dom";
import { UserService } from "../services/user-service";
import { useTranslation } from "react-i18next";

export function WelcomeEmployee() {
  const [employee, setEmployee] = useState<UnverifiedUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { t } = useTranslation();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userService = useMemo(() => new UserService(), []);

  const userid = params.get("userid");
  const token = params.get("token");

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchEmployee = async () => {
      setIsLoading(true);

      try {
        await userService.getUnverifiedUser(
          {
            token: token!,
            userid: Number(userid!),
          },
          (data) => {
            setEmployee(data);
          },
          (error) => {
            setErrors({ verified: "Usuario verificado" });
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };
    if (userid && token) {
      fetchEmployee();
    }
  }, [userService, token, userid]);

  return (
    <div className="relative h-screen">
      <main className="container mx-auto">
        <div className="flex flex-col mx-auto px-4 md:px-0 justify-center items-center pt-16">
          <img className="w-14 h-14" src={Logo} alt="Divix Logo" />
          {!!errors.verified && !isLoading && (
            <div className="mt-20 text-xl text-center font-semibold">
              <p>{errors.verified}</p>
            </div>
          )}
          {!errors.verified && isLoading && (
            <div className="mt-20 text-xl font-semibold">{t("loading")}</div>
          )}
          {!errors.verified && employee && !isLoading && (
            <>
              <h1 className="font-normal text-center tracking-wide text-4xl md:text-5xl pt-14">
                {t("verify-employee.welcome")}
                <span className="block">{employee.name}!</span>
              </h1>

              <h2 className="font-medium text-center tracking-wide max-w-sm md:max-w-xl text-xl md:text-3xl pt-10">
                {t("verify-employee.subtitle")}
              </h2>

              <p className="py-6 text-center font-medium text-lg md:text-2xl">
                {t("verify-employee.message")}
              </p>

              <p className="pb-10 text-center font-medium max-w-xs md:max-w-xl text-base md:text-xl">
                {t("verify-employee.instruction")}
              </p>
              <Link
                to={`/verify-employee?userid=${userid}&token=${token}`}
                className="px-10 md:px-24 hover:bg-black/90 mx-auto py-1 text-white bg-black rounded-xl"
              >
                {t("verify-employee.button")}
              </Link>
            </>
          )}
        </div>
      </main>
    </div>
  );
}
