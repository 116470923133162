import { Box, Button, Modal } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmployeeUser, Group } from "../../types";
import { EditEmployeeForm } from "../form/first-login/edit-employee-form";
import { useToast } from "../../context/toast-context";
import { BranchService } from "../../services/branch-service";
import { Loading } from "../Loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface EditEmployeeProps {
  employee: EmployeeUser;
  updateEmployees: (updatedEmployees: EmployeeUser[]) => void;
  onLeaveConfig?: boolean;
}

export function EditEmployee({
  updateEmployees,
  employee,
  onLeaveConfig = false,
}: EditEmployeeProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [equalSplitTip, setEqualSplitTip] = useState(false);

  const { showToast } = useToast();

  const branchService = useMemo(() => new BranchService(), []);

  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchBranchesInfo = async () => {
      setIsLoading(true);

      if (isModalOpen) {
        try {
          await branchService.getBranchesInformation(
            {
              limit: 100,
              offset: 0,
              branchid: employee.branchId.toString(),
            },
            (data) => {
              setGroups(data[0].groups);
              setEqualSplitTip(data[0].equalSplitTip);
            },
            (error) => showToast(error, "error")
          );
        } finally {
          if (!signal.aborted) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchBranchesInfo();
    return () => {
      controller.abort();
    };
  }, [showToast, branchService, employee.branchId, isModalOpen]);

  return (
    <>
      <Button
        title={t("employee.edit.label")}
        onClick={() => setIsModalOpen(true)}
        sx={{
          margin: 0,
          minWidth: "fit-content",
          background: "transparent",
          border: "1px solid #EF0BB8",
          borderRadius: "50%",
          color: "#EF0BB8",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          width: "2.5rem",
          height: "2.5rem",
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#EF0BB8">
            <path d="M8.293 3.707 1 11v4h4l7.293-7.293-4-4ZM9.707 2.293l4 4 1.465-1.465a2.829 2.829 0 0 0-4-4L9.707 2.293Z" />
          </g>
        </svg>
      </Button>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby={t("employee.edit.label")}
          aria-describedby={t("employee.edit.label")}
        >
          <Box sx={style}>
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
              <h1>{t("employee.edit.title")}</h1>
            </div>
            <div className="p-8">
              {isLoading && <Loading sidebar={false} />}
              {!isLoading && (
                <EditEmployeeForm
                  employee={employee}
                  branchId={employee.branchId}
                  updateEmployees={updateEmployees}
                  setIsModalOpen={setIsModalOpen}
                  groups={groups}
                  equalSplitTip={equalSplitTip}
                  fetchAll={true}
                  onLeaveConfig={onLeaveConfig}
                />
              )}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}
