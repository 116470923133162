import { Box, Skeleton } from "@mui/material";

export function GraphMetricsSkeleton() {
  return (
    <div className="flex flex-col h-full w-full bg-white rounded-lg p-4">
      <div className="flex justify-between pb-4">
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="20%" />
      </div>
      <div className="flex-grow">
        <Box sx={{ height: "100%", width: "100%" }}>
          <Skeleton variant="rectangular" width="100%" height="85%" />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            {[...Array(6)].map((_, i) => (
              <Skeleton key={i} variant="text" width="10%" />
            ))}
          </Box>
        </Box>
      </div>
    </div>
  );
}
